import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  brand: string
  currentLang = "it"
  privacyPolicy: string = 'footer.privacyPolicy';
  cookiePolicy: string = 'footer.cookiePolicy';
  termsCondition: string = 'footer.termsCondition';
  contact: string = 'footer.contact';


  constructor(private translateService: TranslateService) {
    let host = window.location.hostname;
    this.brand = environment.brand[host];
    this.translateService.onLangChange.subscribe( x => {
      this.currentLang = x.lang.substr(0,2);
    });
  }

  ngOnInit(): void {
  }

  getAvisLink(footerLink: string): string {
    switch (footerLink) {
      case 'privacyPolicy':
        switch (this.currentLang) {
          case "de": return "https://www.avis.de/das-kleingedruckte/datenschutzrichtlinien"
          case "fr": return "https://www.avis.fr/a-propos-d-avis/informations-legales/politique-de-confidentialite"
          case "es": return "https://www.avis.es/acerca-de-avis/politica-de-privacidad"
          case "it": return "https://avisbudgetgroup.com/privacy-notice/"
          case "en": return "https://avisbudgetgroup.com/privacy-notice/"
          default:   return "https://avisbudgetgroup.com/privacy-notice/"
        }

      case 'cookiePolicy':
        switch (this.currentLang) {
          case "de": return "https://www.avis.de/das-kleingedruckte/cookie-richtlinien"
          case "fr": return "https://www.avis.fr/a-propos-d-avis/informations-legales/politique-des-cookies"
          case "es": return "https://www.avis.es/acerca-de-avis/politica-de-cookies"
          case "it": return "https://www.avis.co.uk/about-avis/cookie-policy"
          case "en": return "https://www.avis.co.uk/about-avis/cookie-policy"
          default:   return "https://www.avis.co.uk/about-avis/cookie-policy"
        }

      case 'termsCondition':
        switch (this.currentLang) {
          case "de": return "https://www.avis.de/TermsAndCondition"
          case "fr": return "https://www.avis.fr/conditions-generales-avis"
          case "es": return "https://www.avis.es/TermsAndCondition"
          case "it": return "https://avisbudgetgroup.com/terms-of-use/"
          case "en": return "https://avisbudgetgroup.com/terms-of-use/"
          default:   return "https://avisbudgetgroup.com/terms-of-use/"
        }

      case 'contact':
        switch (this.currentLang) {
          case "de": return "https://www.avis.de/kontakt"
          case "fr": return "https://www.avis.fr/nous-contacter"
          case "es": return "https://www.avis.es/contacta-avis"
          case "it": return "https://avisbudgetgroup.com/contact/"
          case "en": return "https://avisbudgetgroup.com/contact/"
          default:   return "https://avisbudgetgroup.com/contact/"
        }

    }
  }
  

  //BUDGET 
  getBudgetLink(footerLink: string): string {
    switch (footerLink) {
      case 'privacyPolicy':
        switch (this.currentLang) {
          case "de": return "https://www.budget.de/privacy-policy"
          case "fr": return "https://www.budget.fr/privacy-policy"
          case "es": return "https://www.budget.es/informacion/legal/politica-privacidad"
          case "it": return "https://www.budget.co.uk/terms-and-conditions/privacy-policy"
          case "en": return "https://www.budget.co.uk/terms-and-conditions/privacy-policy"
          default:   return "https://www.budget.co.uk/terms-and-conditions/privacy-policy"
        }

      case 'cookiePolicy':
        switch (this.currentLang) {
          case "de": return "https://www.budget.de/Allgemeinen-Bedingungen/Cookie-Richtlinie"
          case "fr": return "https://www.budget.fr/Politique-des-cookies"
          case "es": return "https://www.budget.es/informacion/legal/politica-de-cookies"
          case "it": return "https://www.budget.co.uk/terms-and-conditions/cookie-policy"
          case "en": return "https://www.budget.co.uk/terms-and-conditions/cookie-policy"
          default:   return "https://www.budget.co.uk/terms-and-conditions/cookie-policy"
        }

      case 'termsCondition':
        switch (this.currentLang) {
          case "de": return " https://www.budget.de/TermsAndCondition"
          case "fr": return "https://www.budget.fr/Les-conditions-generales"
          case "es": return "https://www.budget.es/TermsAndCondition"
          case "it": return "https://www.budget.co.uk/terms-and-conditions"
          case "en": return "https://www.budget.co.uk/terms-and-conditions"
          default:   return "https://www.budget.co.uk/terms-and-conditions"
        }

      case 'contact':
        switch (this.currentLang) {
          case "de": return "https://www.budget.de/contact-budget"
          case "fr": return "https://www.budget.fr/contact-budget"
          case "es": return "https://www.budget.es/contact-budget/ayuda-y-contacto"
          case "it": return "https://www.budget.co.uk/contact-budget"
          case "en": return "https://www.budget.co.uk/contact-budget"
          default:   return "https://www.budget.co.uk/contact-budget"
        }

    }
  }

}
