import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { forkJoin } from 'rxjs';
import { Schedule } from 'src/app/model/schedule';
import { ScheduleService } from 'src/app/service/schedule.service';
import { TimezoneUtils } from 'src/app/service/utils';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-new-calendar',
  templateUrl: './new-calendar.component.html',
  styleUrls: ['./new-calendar.component.scss']
})
export class NewCalendarComponent implements OnInit {

  //INIZIO TRADUZIONI

  selectDay: string = 'calendar.selectDay';
  check: string = 'calendar.check';
  pickUpDate: string = 'calendar.pickUpDate';
  todayString: string = 'calendar.toDay';
  subtitle: string = 'calendar.subtitle';
  button1: string = 'calendar.button1';
  button2: string = 'calendar.button2';
  stringOrarioNonValorizzato: string = 'calendar.stringOrarioNonValorizzato';
  placeholderCalendar: string = 'calendar.stringOrarioNonValorizzato';
  subtitlegmt: string = 'calendar.subtitlegmt';

  //FINE TRADUZIONI

  openCalendarTime: boolean = false;
  timeList = [];
  timeValorizzato: string;
  dayValorizzato: string;

  @Output() newTime = new EventEmitter<string>();
  @Output() newDate = new EventEmitter<Date>();
  @Output() cancelDate = new EventEmitter<void>();

  @Input() time: string;
  @Input() day: Date;

  @Input() liveSchedule: boolean = false;
  @Input() schedule: Schedule;
  cloneTimeValorizzato: string;
  cloneDayValorizzato: string;
  listaDatePrecedenti: Date[] = [];

  @Output() liveClickable = new EventEmitter<boolean>();
  @Output() activiteLiveSchedule = new EventEmitter<boolean>();

  startDate: string;
  today: Date = new Date();

  @Input() gmt: number;
  @Input() targetTimezoneName: string;

  convertTimezone: {} = {};

  locale: string = "it-IT";

  @HostListener('document:click', ['$event'])
  clickoutTimezone(event) {

    let calendarElement = document.getElementById('calendar')
    if (calendarElement != event.target) {
      this.openCalendarTime = false;
    }
  }

  constructor(private scheduleService: ScheduleService, private datePipe: DatePipe, private activatedRoute: ActivatedRoute,
    private eRef: ElementRef, private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(translation => {
      this.locale = translation.lang.substr(0, 2);
      this.locale = this.locale + "-" + this.locale.toUpperCase();
    });
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.schedule !== undefined && changes.schedule.currentValue !== changes.schedule.previousValue && changes.day !== undefined && changes.day.currentValue !== changes.day.previousValue) {
      this.schedule = changes.schedule.currentValue;

      this.day = changes.day.currentValue;

      this.timeValorizzato = this.schedule.appointmentTime?.toString();
      this.getDate();
      if (this.schedule.appointmentDate != undefined) {
        this.dayValorizzato = this.datePipe.transform(this.schedule.appointmentDate, 'yyyy-MM-dd');

        let convertDateSelected = TimezoneUtils.convertDateTimezone(this.datePipe.transform(this.dayValorizzato, "yyyy-MM-dd"), this.timeValorizzato, environment.originTimezone, this.targetTimezoneName);
        this.dayValorizzato = convertDateSelected.format('yyyy-MM-DD');
        this.timeValorizzato = convertDateSelected.format('HH:mm:ss');
      }

    }

    if ((changes.schedule !== undefined && changes.schedule.currentValue !== changes.schedule.previousValue) ||
      (changes.gmt !== undefined && changes.gmt.currentValue !== changes.gmt.previousValue && changes.targetTimezoneName !== undefined && changes.targetTimezoneName.currentValue !== changes.targetTimezoneName.previousValue)) {


      this.convertTimezone = {};
      let observableBatch = [];
      this.listaDatePrecedenti.forEach(element => {
        observableBatch.push(this.getSlotsDate(this.schedule.reservation.pickUpPoint.area.serviceRoom.id, this.schedule.reservation.pickUpPoint.company.code, this.datePipe.transform(element, "yyyy-MM-dd")));
      });
      forkJoin(
        observableBatch
      )
        .subscribe(result => {

        });

      if (this.dayValorizzato != undefined && changes.targetTimezoneName != undefined) {
        let convertDateSelected = TimezoneUtils.convertDateTimezone(this.datePipe.transform(this.dayValorizzato, "yyyy-MM-dd"), this.timeValorizzato, changes.targetTimezoneName.previousValue, changes.targetTimezoneName.currentValue);
        this.dayValorizzato = convertDateSelected.format('yyyy-MM-DD');
        this.timeValorizzato = convertDateSelected.format('HH:mm:ss');

        this.newTime.emit(this.timeValorizzato);
        this.newDate.emit(new Date(this.dayValorizzato));
      }

    }

    if (changes.liveSchedule !== undefined && changes.liveSchedule.currentValue !== changes.liveSchedule.previousValue) {

      if (this.timeValorizzato != undefined) {
        this.cloneTimeValorizzato = this.timeValorizzato.slice();
        this.cloneDayValorizzato = this.dayValorizzato;
      }
      if (changes.liveSchedule.currentValue == true) {
        this.timeValorizzato = undefined;
        this.dayValorizzato = undefined
      } else {
        this.timeValorizzato = this.cloneTimeValorizzato;
        this.dayValorizzato = this.cloneDayValorizzato;
      }
    }
    if (this.schedule != undefined && this.day != undefined) {
      // this.getSlotsDate(this.schedule.reservation.pickUpPoint.area.serviceRoom.id, this.schedule.reservation.pickUpPoint.company.code, this.datePipe.transform(this.day, "yyyy-MM-dd"));
    }
  }

  getSlotsDate(serviceRoomId, companyCode, day) {

    // let today = TimezoneUtils.convertDateTimezone(this.datePipe.transform(new Date(), "yyyy-MM-dd"), '00:00:00', this.targetTimezoneName, environment.originTimezone)
    this.scheduleService.getSlotsForDate(serviceRoomId, companyCode, day).subscribe((res: any[]) => {
      this.timeList = [];
      res.forEach(element => {
        if (element.availableSlots > 0) {

          let slotToNumber = Number(element.time.replaceAll(':', '') / 100 );
          let pickTimeToNumber = Number(this.schedule.reservation.pickDate.split('T')[1].replaceAll(':', '') / 100);
          let pickUpDay = this.schedule.reservation.pickDate.split('T')[0].split('-')[2];

          if (pickUpDay != Number(day.split('-')[2]) || slotToNumber <= pickTimeToNumber -100) {
            let momentTarget = TimezoneUtils.convertDateTimezone(day, element.time, environment.originTimezone, this.targetTimezoneName);
            let dayTarget = momentTarget.format('yyyy-MM-DD');
            let timeTarget = momentTarget.format('HH:mm:ss');
            if (this.convertTimezone[dayTarget] == undefined) {
              this.convertTimezone[dayTarget] = [];
            }
            this.convertTimezone[dayTarget].push(timeTarget);
            this.convertTimezone[dayTarget].sort();
          }
        }

      });
    })

    return day;
  }

  convertDateFormat(dataFormat: string) {
    let date = new Date(dataFormat);
    return this.datePipe.transform(date, "dd MMM yyyy", null, this.locale);
  }

  getDate() {
    var temp = this.day;
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    for (let i = 1; i < 4; i++) {
      if (temp.getTime() >= today.getTime()) {
        this.listaDatePrecedenti.push(new Date(temp));
      }
      temp.setDate(temp.getDate() - 1);
    }

    this.listaDatePrecedenti = this.listaDatePrecedenti.reverse();
    today.setHours(1)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)
    // let exist: boolean = false;
    // this.listaDatePrecedenti.forEach(element => {
    //   console.log("element",element)
    //   console.log("today",today)

    //   if (element.getTime() == today.getTime()) {
    //     exist = true;
    //   }
    // });
    // this.liveClickable.emit(exist);
  }

  selectTime(time) {
    this.timeValorizzato = time;
    this.newTime.emit(time)
  }

  selectData(data) {
    this.cancelDate.emit();
    this.timeValorizzato = '';
    this.dayValorizzato = data;
    this.newDate.emit(data);
  }

  cancel() {
    this.dayValorizzato = undefined;
    this.timeValorizzato = '';
    this.openCalendarTime = false;
    this.cancelDate.emit();
  }

  apply() {
    this.openCalendarTime = false;
  }

  openCalendar() {
    this.openCalendarTime = (!this.openCalendarTime && !this.liveSchedule);
  }

}
