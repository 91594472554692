import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-four-action',
  templateUrl: './four-action.component.html',
  styleUrls: ['./four-action.component.scss']
})
export class FourActionComponent implements OnInit {

  //inizio traduzioni
  yourJourney: string = 'home.yourJourney';
  chooseCall1: string = 'home.chooseCall1';
  chooseCall2: string = 'home.chooseCall2';
  subchooseCall: string = 'home.subchooseCall';
  smartcheckoutOperator1: string = 'home.smartcheckoutOperator1';
  smartcheckoutOperator2: string = 'home.smartcheckoutOperator2';
  pickupVehicle1: string = 'home.pickupVehicle1';
  pickupVehicle2: string = 'home.pickupVehicle2';
  subPickupVehicle: string = 'home.subPickupVehicle';
  fastSmart1: string = 'home.fastSmart1';
  fastSmart2: string = 'home.fastSmart2';
  subFastSmart: string = 'home.subFastSmart';
  subSmartcheckoutOperator: string = 'home.subSmartcheckoutOperator';
  //fine traduzioni

  company: string = 'avis'
  constructor() { }

  ngOnInit(): void {

    let host = window.location.hostname;
    let brand = environment.brand[host]

    let company: string = environment.theme[brand]
    this.company = (company) ? company.toLowerCase() : 'avis';

  }

}
