import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DialogConfermaComponent } from 'src/app/feature/components/dialog-conferma/dialog-conferma.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Schedule } from 'src/app/model/schedule';
import { Reservation } from 'src/app/model/reservation';
import { ScheduleService } from 'src/app/service/schedule.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { DialogDeleteComponent } from 'src/app/feature/components/dialog-delete/dialog-delete.component';

import * as moment from 'moment-timezone';
import { TimezoneUtils } from 'src/app/service/utils';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO, NgxIntlTelInputComponent, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { StateService } from 'src/app/service/stateService.service';
// import { Timezone } from 'src/app/enums/timezone';

declare var firebase : any;
@Component({
  selector: 'app-card-prenotazione',
  templateUrl: './card-prenotazione.component.html',
  styleUrls: ['./card-prenotazione.component.scss']
})


export class CardPrenotazioneComponent implements OnInit {

  //INIZIO TRADUZIONI
  numRes: string = 'home.numRes';
  yourPickUp: string = 'home.yourPickUp';
  subtitleEdit: string = 'home.subtitleEdit';
  callPossible: string = 'home.callPossible';
  insertEmail: string = 'home.insertEmail';
  receiveEmail1: string = 'home.receiveEmail1';
  // receiveEmail2: string = 'home.receiveEmail2';
  insertPhone: string = 'home.insertPhone';
  numbWhatsapp: string = 'home.numbWhatsapp';
  button1: string = 'home.button1';
  button2: string = 'home.button2';
  button3: string = 'home.button3';
  timeZoneTitle: string = 'home.timeZoneTitle';
  //FINE TRADUZIONI

  smartCheckForm = new FormGroup({});

  @Input() isCreazione: boolean;
  @Input() schedule: Schedule = new Schedule();
  @Input() resNo: number;
  @Input() email: string;
  @Input() startDate: Date;

  @Input() pickUp: string;
  @Input() startTime: string;
  @Input() gmt: number;
  @Input() targetTimezoneName: string;

  @Input() liveSchedule: boolean = false;

  @ViewChild(NgxIntlTelInputComponent) prefixPicker:NgxIntlTelInputComponent;

  openPrefisso: boolean = false;
  prefissoSelezionato: any ={ dialCode: "39", iso2: "gb" };
  // prefissoList = ["880", "32", "226", "359", "387", "+1-246", "681", "590", "+1-441", "673", "591", "973", "257", "229", "975", "+1-876", "267", "685", "599", "55", "+1-242", "+44-1534", "375", "501", "7", "250", "381", "670", "262", "993", "992", "40", "690", "245", "+1-671", "502", "30", "240", "590", "81", "592", "+44-1481", "594", "995", "+1-473", "44", "241", "503", "224", "220", "299", "350", "233", "968", "216", "962", "385", "509", "36", "852", "504", "58", "+1-787 1-939", "970", "680", "351", "47", "595", "964", "507", "689", "675", "51", "92", "63", "870", "48", "508", "260", "212", "372", "20", "27", "593", "39", "84", "677", "251", "252", "263", "966", "34", "291", "382", "373", "261", "590", "212", "377", "998", "95", "223", "853", "976", "692", "389", "230", "356", "265", "960", "596", "+1-670", "+1-664", "222", "+44-1624", "256", "255", "60", "52", "972", "33", "246", "290", "358", "679", "500", "691", "298", "505", "31", "47", "264", "678", "687", "227", "672", "234", "64", "977", "674", "683", "682", "225", "41", "57", "86", "237", "56", "61", "1", "242", "236", "243", "420", "357", "61", "506", "599", "238", "53", "268", "963", "599", "996", "254", "211", "597", "686", "855", "+1-869", "269", "239", "421", "82", "386", "850", "965", "221", "378", "232", "248", "7", "+1-345", "65", "46", "249", "+1-809 and 1-829", "+1-767", "253", "45", "+1-284", "49", "967", "213", "1", "598", "262", "1", "961", "+1-758", "856", "688", "886", "+1-868", "90", "94", "423", "371", "676", "370", "352", "231", "266", "66", "228", "235", "+1-649", "218", "379", "+1-784", "971", "376", "+1-268", "93", "+1-264", "+1-340", "354", "98", "374", "355", "244", "+1-684", "54", "61", "43", "297", "91", "+358-18", "994", "353", "62", "380", "974", "258"];
  whatsapp: boolean = false;

  openChangeTimezone: boolean = false;
  timezoneList: any[] = moment.tz.names();

  liveClickable: boolean = true;

  liveHidden: boolean = false;
  today: Date = new Date();
  pickDate: Date;

  locale: string = "it-IT";
  nameEnviroment: string;

  // prove prefisso nuovo
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [];


  @HostListener('document:click', ['$event'])
  clickoutTimezone(event) {

    let timezoneElement = document.getElementById('timezone');
    let prefissoElement = document.getElementById('prefisso');

    if (timezoneElement != event.target) {
      this.openChangeTimezone = false;
    }
    if (prefissoElement != event.target) {
      this.openPrefisso = false;
    }
  }

  constructor(private dialog: MatDialog, private scheduleService: ScheduleService, private eRef: ElementRef,private stateService : StateService,
    private datePipe: DatePipe, private cdRef: ChangeDetectorRef, private translateService: TranslateService, private router: Router
  ) {
    let browserLang = this.translateService.getBrowserLang();
    let isoExist = Object.values(CountryISO).some((v) => v === browserLang)
    if(browserLang == "en") {
      this.prefissoSelezionato.iso2 = 'gb';
    } else if(isoExist) {
      this.prefissoSelezionato.iso2 = browserLang;
    }

    let host = window.location.hostname;
    this.nameEnviroment = environment.brand[host];
    this.translateService.onLangChange.subscribe(translation => {
      this.locale = translation.lang.substr(0, 2);
      this.locale = this.locale + "-" + this.locale.toUpperCase();
    });

  }

  ngAfterViewInit() {
    if(this.prefixPicker && this.prefixPicker.selectedCountry && this.prefixPicker.selectedCountry.dialCode) {
      this.prefissoSelezionato.dialCode = this.prefixPicker.selectedCountry.dialCode;
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {

    this.smartCheckForm.addControl('dataRitiro', new FormControl(''));
    this.smartCheckForm.addControl('email', new FormControl('',));
    this.smartCheckForm.addControl('prefisso', new FormControl(''));
    this.smartCheckForm.addControl('callPossible', new FormControl(''));
    this.smartCheckForm.addControl('tel', new FormControl('', [Validators.required]));
    this.smartCheckForm.addControl('whatsapp', new FormControl(false));
    this.smartCheckForm.addControl('numRes', new FormControl(''));
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.schedule !== undefined && changes.schedule.currentValue !== changes.schedule.previousValue) {
      this.pickDate =   new Date(this.schedule.reservation.pickDate);

      // this.pickDate = TimezoneUtils.convertDateTimezone(this.schedule.reservation.pickDate.split('T')[0], this.schedule.reservation.pickDate.split('T')[1], this.targetTimezoneName, environment.originTimezone).toDate();
      if (changes.schedule.currentValue.status == 'IN_ATTESA') {
        this.liveSchedule = true;
      }

      let pickDay = new Date(this.schedule.reservation.pickDate);
      pickDay.setHours(0,0,0,0);
      if (this.today.getTime() >= pickDay.getTime()) {

        this.liveClickable = false;
        this.liveSchedule = true;
      }
      let dif = new Date(this.schedule.reservation.pickDate).getTime() - this.today.getTime();
      let hour = (dif / (1000 * 60 * 60));
      if (hour > 48) {
        this.liveHidden = true;
      }

      this.prefissoSelezionato.iso2 = this.schedule.reservation.phonePrefixIso;
      this.smartCheckForm.get('tel').setValue(changes.schedule.currentValue.reservation.phone);
      this.smartCheckForm.get('whatsapp').setValue(changes.schedule.currentValue.reservation.whatsapp);

    }
  }

  selectPrefisso(prefisso) {
    this.prefissoSelezionato = prefisso;
    this.openPrefisso = false;
  }

  openDiaogConfirm() {
    let host = window.location.hostname;
    this.schedule.reservation.phone = this.smartCheckForm.get('tel').value;
    this.schedule.reservation.whatsapp = this.smartCheckForm.get('whatsapp').value;
    this.schedule.reservation.phonePrefix = this.prefissoSelezionato.dialCode;
    this.schedule.reservation.phonePrefixIso = this.prefissoSelezionato.iso2;
    this.schedule.reservation.brand = { 'code': environment.brand[host] };

    this.schedule.language = {locale: this.stateService.language + '_' + this.stateService.language.toUpperCase() };

    let dialogRef = this.dialog.open(DialogConfermaComponent, {
      minWidth: 730.2,
      maxWidth: 500.2,
      data: { startDate: this.startDate, email: this.email, phone: this.schedule.reservation.phone, liveSchedule: this.liveSchedule, startTime: this.startTime, resNo: this.resNo, prefisso: this.prefissoSelezionato.dialCode, gmt: this.gmt, targetTimeZoneName: this.targetTimezoneName, locale: this.locale }
    });

    dialogRef.afterClosed().subscribe(navigationExtras => {
      if (navigationExtras) {
        if (this.liveSchedule == true) {
          this.scheduleService.createLiveAppointment(this.schedule)
            .subscribe(
              res => {
                this.router.navigate(['/smartcheckout'], navigationExtras);
              },
              err => {
                this.router.navigate(['/smartcheckout-non-trovata', { errorType: "2" }]);
              }
            );
        } else {
          let convertedDate = TimezoneUtils.convertDateTimezone(this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.startTime, this.targetTimezoneName, environment.originTimezone);

          this.schedule.appointmentDate = convertedDate.format('yyyy-MM-DD');
          this.schedule.appointmentTime = convertedDate.format('HH:mm:ss');


          this.scheduleService.createAppointment(this.schedule)
            .subscribe(
              res => {
                this.router.navigate(['/smartcheckout'], navigationExtras);
              },
              err => {
                this.router.navigate(['/smartcheckout-non-trovata', { errorType: "2" }]);
              }
            );

        }
      }
    });

    firebase.analytics().logEvent('click_prenotazione_new');

  }

  openDiaogEdit() {
    this.schedule.reservation.phone = this.smartCheckForm.get('tel').value;
    this.schedule.reservation.whatsapp = this.smartCheckForm.get('whatsapp').value;
    this.schedule.reservation.phonePrefix = this.prefissoSelezionato.dialCode;
    this.schedule.reservation.phonePrefixIso = this.prefissoSelezionato.iso2;

    this.schedule.language = {locale: this.stateService.language + '_' + this.stateService.language.toUpperCase() };


    let dialogRef = this.dialog.open(DialogConfermaComponent, {
      minWidth: 730.2,
      maxWidth: 500.2,
      data: { startDate: this.startDate, email: this.email, phone: this.schedule.reservation.phone, liveSchedule: this.liveSchedule, startTime: this.startTime, resNo: this.resNo, prefisso: this.prefissoSelezionato.dialCode, gmt: this.gmt, targetTimeZoneName: this.targetTimezoneName, locale: this.locale }
    });

    dialogRef.afterClosed().subscribe(navigationExtras => {
      if (navigationExtras) {

        if (this.liveSchedule == true) {
          this.scheduleService.createLiveAppointment(this.schedule)
            .subscribe(
              res => {
                this.router.navigate(['/smartcheckout'], navigationExtras);
              },
              err => {
                this.router.navigate(['/smartcheckout-non-trovata', { errorType: "2" }]);
              }
            );
        } else {
          let convertedDate = TimezoneUtils.convertDateTimezone(this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.startTime, this.targetTimezoneName, environment.originTimezone);
          this.schedule.appointmentDate = convertedDate.format('yyyy-MM-DD');
          this.schedule.appointmentTime = convertedDate.format('HH:mm:ss');
          this.scheduleService.editAppointment(this.schedule)
            .subscribe(
              res => {
                this.router.navigate(['/smartcheckout'], navigationExtras);
              },
              err => {
                this.router.navigate(['/smartcheckout-non-trovata', { errorType: "2" }]);
              }
            );
        }
      }
    });

    firebase.analytics().logEvent('click_prenotazione_edit');
  }

  changed(value: boolean) {
    this.liveSchedule = value;
  }

  changedWhatsapp(value: boolean) {
    this.whatsapp = value;
  }

  changeDate(item: Date) {
    this.startDate = item;
  }

  changeTime(item: string) {
    this.startTime = item;
  }

  cancelDate() {
    this.startDate = null;
    this.startTime = null;
  }

  gmtValue(item: number) {
    this.gmt = item;
  }

  timezoneNameValue(item: string) {
    this.targetTimezoneName = item;
  }


  setTimezoneName(item: string) {
    this.gmt = TimezoneUtils.calculateGMT(item);

    this.targetTimezoneName = item;
    this.openChangeTimezone = false;
    this.schedule.timezone = this.targetTimezoneName;
  }


  calculateGmt(item: string) {
    return TimezoneUtils.calculateGMT(item);
  }


  setClickable(item: boolean) {
    this.liveClickable = item;
  }

  deleteAppointment(idSchedule: number) {

    this.scheduleService.deleteAppointment(idSchedule).subscribe(res => {

    })
    firebase.analytics().logEvent('click_prenotazione_delete');

  }

  openDiaogDelete(idSchedule: number) {
    let dialogRef = this.dialog.open(DialogDeleteComponent, {
      minWidth: 730.2,
      maxWidth: 500.2,
      panelClass: 'modalDelete'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAppointment(idSchedule)
      }
    });
  }


}
