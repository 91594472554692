<div class="row m-0">
    <div class="col-md-1 offset-md-11 col-12 p-0 flex-end-x ">
        <!-- <button mat-fab class="font-10 colorModale" > -->
        <mat-icon (click)="chiudiModal()" class="primary-color font-30">close</mat-icon>
        <!-- </button> -->
    </div>
    <div class="col-md-11 col-12 m-b-15 font-30 bold p-0 family-Prompt font-size-18">
        <span>
            {{title1 | translate}} <br>
            {{title2 | translate}}
        </span>
    </div>

</div>

<div class="row">
    <div class="col-md-5 col-12 font-24 font-size-18 family-Prompt bold" *ngIf="!liveSchedule">
        <span>
            {{dateTrad | translate}}
        </span>
    </div>
    <div class="col-md-6 col-12 font-24 font-size-18">
        <span *ngIf="!liveSchedule">{{date | date: 'dd MMM yyyy' : '' : locale}}</span>
    </div>
</div>

<div class="col font-24 font-size-18 text-center m-t-15 m-b-15" *ngIf="liveSchedule">
    <span>{{dateTrad2 | translate}}</span>

</div>

<div class="row" *ngIf="!liveSchedule">
    <div class="col-md-5 col-12 font-24 font-size-18 family-Prompt bold ">
        <span>
            {{timeTrad | translate}}
        </span>
    </div>
    <div class="col-md-6 col-12 font-24 font-size-18">
        <span>{{time | slice:0:5}}</span>
    </div>
</div>

<div class="row" >
    <div class="col-md-5 col-12 font-24 font-size-18 family-Prompt bold ">
        <span>
            {{timeZone | translate}}
        </span>
    </div>
    <div class="col-md-6 col-12 font-24 font-size-18">
        <span>{{targetTimeZoneName}} (GMT {{gmt >= 0 ? '+' : ''}}{{gmt}})</span>
    </div>
</div>

<div class="row">
    <div class="col-md-5 col-12 family-Prompt  font-24 font-size-18 bold">
        <span>
            {{emailTrad | translate}}
        </span>
    </div>
    <div class="col-md-6 col-12  font-24 font-size-18">
        <span>{{email}}</span>
    </div>
</div>

<div class="row">
    <div class="col-md-5 col-12 font-24 font-size-18  family-Prompt bold">
        <span>
            {{numbPhone | translate}}
        </span>
    </div>
    <div class="col-md-6 col-12 font-24 font-size-18">
        <span> {{prefisso}} - {{phone}}</span>
    </div>
</div>


<div class="row m-t-55">
    <div class="col-md-3 offset-md-6 col">
        <button type="submit" class="standard-form__submit button primary-background font-20" (click)="conferma()">
            {{button1 | translate}}</button>
    </div>
    <div class="col-md-3 col">
        <button class="annullaButton font-20" (click)="chiudiModal()">{{button2 | translate}}</button>

    </div>
</div>