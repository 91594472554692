import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiEndPointService {

  country = null;
  private endpointUriArray = environment.endpointUriArray;
  private endpoint;
  private endpointUri;
  public endpoint$ = new BehaviorSubject(this.endpointUriArray[0]);


  constructor(private route: ActivatedRoute) {
    // this.paramFromUrl();
  }

  // private paramFromUrl() {
  //   this.route.queryParams.subscribe(params => {
  //     if (params.country) {
  //       this.country = params.country.toLowerCase();
  //       this.endpoint = this.filterEndpointUri(this.country)[0];
  //     } else {
  //       this.endpoint = this.filterEndpointUri('it')[0];
  //     }
  //   });
  //   this.endpoint$.next(this.endpoint);
  // }

  private filterEndpointUri(country: string) {
    if (country) {
      return this.endpointUriArray.filter(uri => uri.country == country)[0]
    }
  }

  setEndpointUri(country): void {
    if (country) {
      this.country = country.toLowerCase();
      this.endpoint = this.filterEndpointUri(this.country);
    } else {
      this.endpoint = this.filterEndpointUri(environment.defaultCountry.toLowerCase());
    }
    this.endpoint$.next(this.endpoint);
  }

  getEndpointUri(): BehaviorSubject<any> {
    return this.endpoint$;
  }
}
