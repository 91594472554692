import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var firebase: any;

@Component({
  selector: 'app-smartcheckout-delete',
  templateUrl: './smartcheckout-delete.component.html',
  styleUrls: ['./smartcheckout-delete.component.scss']
})
export class SmartcheckoutDeleteComponent implements OnInit {

  //INIZIO TRADUZIONI
  title: string = 'pageRiepilogoDelete.title';
  subtitle: string = 'pageRiepilogoDelete.subtitle';

  //FINE TRADUZIONI
  constructor(private router: Router) {
    firebase.analytics().logEvent('event_delete_fine');

   }

  ngOnInit(): void {
  }


  goToBrand(){
    this.router.navigate(['/brand']);

  }
}
