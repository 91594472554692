<div class="row m-0">
    <div class="col-md-6 offset-md-3">

        <div class="row m-0">
            <div class="col-md-12  col-12 p-0 text-center font-40 primary-color bold m-t-20  font-Prompt font-size-30">
                {{title1 | translate}}
                <!-- <br>
                {{title2 | translate}} -->
            </div>
        </div>

        <div class="row m-0 text-center p-t-30">
            <div class="col-md-12  col-12">
                <img src="./assets/images/check.svg">

            </div>
        </div>

        <div class="row m-0">
            <div class="col-md-12 col-12 text-center  font-30 bold color-green font-Prompt">
                {{thank | translate}}
            </div>
        </div>

        <div class="row m-0" *ngIf="!liveSchedule">
            <div class="col-md-12  col-12   font-16 bold m-t-20  color-red text-center-mobile">
                {{subtitle | translate}}
            </div>
        </div>

        <div class="row m-0" *ngIf="liveSchedule">
            <div class="col-md-12  col-12   font-16 bold m-t-20 m-b-20 text-center-mobile">
                {{subtitleCanPossible | translate}}
            </div>
        </div>

        <div class="row m-0">
            <div class="col-md-5  col-12  bold font-20 font-Prompt ">
                {{reservationNumber | translate}}
            </div>
            <div class="col-md-1  col-12 font-20 font-Prompt ">
                {{resNo}}
            </div>
        </div>

        <div class="row m-0 p-b-30 p-t-30">
            <div class="col-md-12  col-12  bg-grey-chiaro">
                <div class="row m-t-15 m-b-10">
                    <div class="col-md-6 col-12   font-30 bold font-Prompt font-size-20">
                        {{appointament | translate}}
                    </div>
                </div>
                <mat-divider></mat-divider>


                <div class="row m-b-25 m-t-15" *ngIf="!liveSchedule">
                    <div class="col-md-5 col-12  font-20">
                        <span class="bold ">{{textDate | translate}}</span>
                        <span>
                            {{date | date: 'dd MMM yyyy' : '' : locale}}
                        </span>
                    </div>
                    <div class="col-md-4 col-12  font-20">
                        <span class="bold">{{textTime | translate}}</span>
                        <span>
                            {{time | slice:0:5}}
                        </span>
                    </div>
                </div>

                <div class="row m-b-25">
                    <div class="col-md-5 col-12  font-20">
                        <span class="bold font-Prompt">
                            {{timeZone | translate}}
                        </span>

                    </div>
                    <div class="col-md-4 col-12   font-20">
                        <span>
                            (GMT {{gmt >= 0 ? '+' : ''}}{{gmt}}) {{targetTimeZoneName}}
                        </span>
                    </div>
                </div>

                <div class="row m-b-25 " *ngIf="liveSchedule">
                    <div class="col-md-12 col-12  font-20">
                        {{nextOne | translate}}
                    </div>
                </div>

                <div class="row m-b-25">
                    <div class="col-md-5 col-12  font-20">
                        <span class="bold font-Prompt">
                            {{titleMobileNumber | translate}}
                        </span>

                    </div>
                    <div class="col-md-4 col-12   font-20">
                        <span>
                            {{prefisso}}-{{phone}}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row m-0">
            <div class="col-md-12  col-12   font-24 bold font-Prompt  m-b-20 font-size-20">
                {{titleFooter | translate}}
            </div>
        </div>

        <div class="row m-0">
            <div class="col-md-12  col-12  font-20  ">
                {{subtitleFooter1 | translate}}
            </div>
        </div>

        <div class="row m-0">
            <div class="col-md-12  col-12  font-20  m-t-20 m-b-20">
                {{subtitleFooter2 | translate}} 
                
                <span class="normal-bold"> 
                    {{email}}
                </span>
              
            </div>
        </div>

    </div>
</div>

<app-four-action></app-four-action>