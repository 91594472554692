<label class="standard-form__label font-12 normal-bold" for="">{{selectDay | translate}}</label>

<div class="row m-0 ">
    <div class="standard-form__input standard-form__select" [ngClass]="{'borderDiv' : openCalendarTime}" id="calendar"
        (click)="openCalendar()"
        [ngClass]="{'bg-grey-chiaro' : liveSchedule == true, 'standard-form__select' : liveSchedule == false }">
        <span class="centerVerticalText" *ngIf="liveSchedule">{{check | translate}} </span>

        <span class="centerVerticalText color-light-grey p-l-10" *ngIf="dayValorizzato == undefined && !liveSchedule ">
            {{placeholderCalendar | translate}}
        </span>
        <span class="centerVerticalText bold p-l-10" *ngIf="!liveSchedule && dayValorizzato != undefined">
            {{convertDateFormat(dayValorizzato)}}
        </span>

        <span class="centerVerticalText bold" *ngIf="timeValorizzato && !liveSchedule">
            / {{timeValorizzato | slice:0:5}} GMT {{gmt >= 0 ? '+' : ''}} {{gmt}}
        </span>
    </div>
</div>


<div class="row divSopra m-0 " *ngIf="openCalendarTime">
    <div class="col p-0">
        <div class="row  width-100 m-0 p-0 borderCalender  ">
            <div class="col p-0">
                <div class="row bgWhite m-0">
                    <div class="col font-16 bold p-15">
                        <mat-icon class="color-green text-center font-15" style="vertical-align: -2px;">
                            fiber_manual_record</mat-icon>
                        <span>
                            {{pickUpDate | translate}}
                        </span>
                        <span class="font-16 colorDate">
                            {{schedule?.reservation.pickDate  | date: 'dd MMM yyyy' : '' : locale}}
                        </span>
                    </div>
                </div>

                <div class="row bg-grey-chiaro m-0">
                    <div class="col-8 ">
                        <div class="row m-0">
                            <div class="col p-l-10 ">
                                <span class="font-16 bold">
                                    {{todayString | translate}}
                                </span>
                                <span class="font-16 colorDate">
                                    {{today | date: 'dd MMM yyyy' : '' : locale}}
                                </span>

                            </div>
                        </div>
                        <div class="row m-0 p-b-15">
                            <div class="col font-12">
                                {{subtitle | translate}}
                            </div>
                        </div>

                        <div class="row  m-0">
                            <div class="col  font-12">
                                {{ subtitlegmt | translate}}GMT {{gmt >= 0 ? '+' : ''}}{{gmt}}
                                {{targetTimezoneName?.replace('_', ' ')}}
                            </div>
                        </div>


                        <div class="row m-0 p-t-10 p-b-10" *ngFor="let datePrecedenti of convertTimezone | keyvalue">
                            <div class="col">
                                <mat-chip-list aria-label="Fish selection">
                                    <mat-chip class="chip" (click)="selectData(datePrecedenti.key)"
                                        [ngClass]="{'colorChip' : dayValorizzato == datePrecedenti?.key}">
                                        {{convertDateFormat(datePrecedenti.key)}}
                                    </mat-chip>
                                </mat-chip-list>

                            </div>
                        </div>
                    </div>
                    <div class="col-4 divider divTimepicker ">

                        <div class="lineHeight font-14 p-2 m-t-10" style="color: grey;">
                            <span *ngIf="!dayValorizzato">
                                {{stringOrarioNonValorizzato | translate}}
                            </span>
                        </div>


                        <span *ngIf="dayValorizzato">
                            <div class="p-l-20 p-t-15  p-b-15 hoverTime flex-start-x font-20"
                                *ngFor="let ora of convertTimezone[dayValorizzato]" (click)="selectTime(ora)"
                                [ngClass]="{'oraSelezionata' : timeValorizzato == ora}">
                                <span>
                                    {{ora | slice:0:5}}
                                </span>
                            </div>
                        </span>


                    </div>

                </div>

                <div class="row m-0 bgWhite p-t-20 p-b-15 ">
                    <div class="col-md-4 offset-md-2  col-12 m-b-10 ">
                        <button type="submit" class="standard-form__submit button primary-background"
                            style="padding-left: 0; padding-right: 0;"
                            [disabled]="timeValorizzato == '' ||  dayValorizzato == undefined" (click)="apply()">
                            {{button1 | translate}}
                        </button>
                    </div>


                    <div class="col-md-4 col-12">
                        <button type="submit" (click)="cancel()" style="padding-left: 0; padding-right: 0"
                            class="standard-form__submit  annullaButton primary-background"> {{button2 | translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>


    </div>

</div>