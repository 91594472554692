import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss']
})
export class DialogDeleteComponent implements OnInit {

  //INIZIO TRADUZIONI
  title1: string = 'dialogDelete.title1';
  title2: string = 'dialogDelete.title2';
  subtitle: string = 'dialogDelete.subtitle';
  yes: string = 'dialogDelete.yes';
  no: string = 'dialogDelete.no';

  //FINE TRADUZIONI

  constructor(public dialogRef: MatDialogRef<DialogDeleteComponent>, private router: Router,
     @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  }


  chiudiModal() {
    this.dialogRef.close(false);
  }

  conferma() {
    this.dialogRef.close(true);

    this.router.navigate(['/smartcheckout-delete']);

  }

}
