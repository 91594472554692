import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';

declare var firebase: any;

@Component({
  selector: 'app-smartechkout-prenotazione-non-trovata',
  templateUrl: './smartechkout-prenotazione-non-trovata.component.html',
  styleUrls: ['./smartechkout-prenotazione-non-trovata.component.scss']
})
export class SmartechkoutPrenotazioneNonTrovataComponent implements OnInit {

  errorType: string = "0";

  //INIZIO TRADUZIONI
  title1: string = 'pagePrenNonTrovata.title1';
  title2: string = 'pagePrenNonTrovata.title2'; // 0: errore generica
  title3: string = 'pagePrenNonTrovata.title3'; // 1: errore data scaduta
  title4: string = 'pagePrenNonTrovata.title4'; // 2: errore prenotazione fallita
  subtitle: string = 'pagePrenNonTrovata.subtitle';
  //FINE TRADUZIONI

  constructor(private route: ActivatedRoute) { 
    firebase.analytics().logEvent('event_nontrovata_fine');

  }

  ngOnInit(): void {
    if(this.route.snapshot.paramMap.get("errorType")) {
      this.errorType =  this.route.snapshot.paramMap.get("errorType");
    } 
  }

}
