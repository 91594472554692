import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Schedule } from 'src/app/model/schedule';
import { ScheduleService } from 'src/app/service/schedule.service';
import { environment } from 'src/environments/environment';
import { DialogConfermaComponent } from '../components/dialog-conferma/dialog-conferma.component';
import { DialogDeleteComponent } from '../components/dialog-delete/dialog-delete.component';

import * as moment from 'moment-timezone';
import { TimezoneUtils } from 'src/app/service/utils';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO, NgxIntlTelInputComponent, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { StateService } from 'src/app/service/stateService.service';
import {ApiEndPointService} from "../../service/api-end-point.service";
// import { Timezone } from 'src/app/enums/timezone';
declare var firebase: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  //INIZIO TRADUZIONI
  title1: string = 'home.title1';
  title2: string = 'home.title2';
  subtitle: string = 'home.subtitle';
  subtitle2: string = 'home.subtitle2';
  numRes: string = 'home.numRes';
  yourPickUp: string = 'home.yourPickUp';

  subtitleEdit: string = 'home.subtitleEdit';

  callPossible: string = 'home.callPossible';
  insertEmail: string = 'home.insertEmail';
  insertPhone: string = 'home.insertPhone';
  receiveEmail1: string = 'home.receiveEmail1';
  // receiveEmail2: string = 'home.receiveEmail2';
  yourJourney: string = 'home.yourJourney';
  chooseCall1: string = 'home.chooseCall1';
  chooseCall2: string = 'home.chooseCall2';
  subchooseCall: string = 'home.subchooseCall';
  smartcheckoutOperator1: string = 'home.smartcheckoutOperator1';
  smartcheckoutOperator2: string = 'home.smartcheckoutOperator2';
  pickupVehicle1: string = 'home.pickupVehicle1';
  pickupVehicle2: string = 'home.pickupVehicle2';
  subPickupVehicle: string = 'home.subPickupVehicle';
  fastSmart1: string = 'home.fastSmart1';
  fastSmart2: string = 'home.fastSmart2';
  subFastSmart: string = 'home.subFastSmart';
  subSmartcheckoutOperator: string = 'home.subSmartcheckoutOperator';
  button1: string = 'home.button1';
  button2: string = 'home.button2';
  button3: string = 'home.button3';
  numbWhatsapp: string = 'home.numbWhatsapp';
  timeZoneTitle: string = 'home.timeZoneTitle';

  //FINE TRADUZIONI
  isCreazione: boolean;
  schedule: Schedule;
  resNo: number;
  email: string;
  pickUp: string;
  brand: string;
  startDate: Date = new Date();
  liveSchedule: boolean = false;
  smartCheckFormMobile = new FormGroup({});
  openPrefisso: boolean = false;
  // prefissoSelezionato: string = "+39";
  prefissoSelezionato: any = { dialCode: "39", iso2: "it" };

  // prefissoList = ["880", "32", "226", "359", "387", "+1-246", "681", "590", "+1-441", "673", "591", "973", "257", "229", "975", "+1-876", "267", "685", "599", "55", "+1-242", "+44-1534", "375", "501", "7", "250", "381", "670", "262", "993", "992", "40", "690", "245", "+1-671", "502", "30", "240", "590", "81", "592", "+44-1481", "594", "995", "+1-473", "44", "241", "503", "224", "220", "299", "350", "233", "968", "216", "962", "385", "509", "36", "852", "504", "58", "+1-787 1-939", "970", "680", "351", "47", "595", "964", "507", "689", "675", "51", "92", "63", "870", "48", "508", "260", "212", "372", "20", "27", "593", "39", "84", "677", "251", "252", "263", "966", "34", "291", "382", "373", "261", "590", "212", "377", "998", "95", "223", "853", "976", "692", "389", "230", "356", "265", "960", "596", "+1-670", "+1-664", "222", "+44-1624", "256", "255", "60", "52", "972", "33", "246", "290", "358", "679", "500", "691", "298", "505", "31", "47", "264", "678", "687", "227", "672", "234", "64", "977", "674", "683", "682", "225", "41", "57", "86", "237", "56", "61", "1", "242", "236", "243", "420", "357", "61", "506", "599", "238", "53", "268", "963", "599", "996", "254", "211", "597", "686", "855", "+1-869", "269", "239", "421", "82", "386", "850", "965", "221", "378", "232", "248", "7", "+1-345", "65", "46", "249", "+1-809 and 1-829", "+1-767", "253", "45", "+1-284", "49", "967", "213", "1", "598", "262", "1", "961", "+1-758", "856", "688", "886", "+1-868", "90", "94", "423", "371", "676", "370", "352", "231", "266", "66", "228", "235", "+1-649", "218", "379", "+1-784", "971", "376", "+1-268", "93", "+1-264", "+1-340", "354", "98", "374", "355", "244", "+1-684", "54", "61", "43", "297", "91", "+358-18", "994", "353", "62", "380", "974", "258"];
  whatsapp: boolean = false;
  @Input() startTime: string;

  @ViewChild(NgxIntlTelInputComponent) prefixPicker: NgxIntlTelInputComponent;

  telefono: string;
  serviceRoomId: number;
  codeCompany: string;
  mobile: boolean = false;
  accordion: boolean = false;
  liveClickable: boolean = true;
  liveHidden: boolean = false;
  today: Date = new Date();
  pickDate: Date;

  gmt: number;
  targetTimezoneName: string;

  locale: string = "it-IT";

  openChangeTimezone: boolean = false;
  timezoneList: any[] = moment.tz.names();

  nameEnviroment: string;
  // prove prefisso nuovo
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef,
    private stateService: StateService,
    private scheduleService: ScheduleService,
    private dialog: MatDialog,
    public translateService: TranslateService,
    private apiEndPointService: ApiEndPointService
  ) {

    let browserLang = this.translateService.getBrowserLang();
    let isoExist = Object.values(CountryISO).some((v) => v === browserLang)
    if (browserLang == "en") {
      this.prefissoSelezionato.iso2 = 'gb';
    } else if (isoExist) {
      this.prefissoSelezionato.iso2 = browserLang;
    }

    let host = window.location.hostname;
    this.nameEnviroment = environment.brand[host];
    this.translateService.onLangChange.subscribe(translation => {
      this.locale = translation.lang.substr(0, 2);
      this.locale = this.locale + "-" + this.locale.toUpperCase();
    });

    firebase.analytics().logEvent('event_inizio_entrata');
    window['ga-disable-analyticsId'] = true;

  }

  ngAfterViewInit() {
    if (this.prefixPicker && this.prefixPicker.selectedCountry && this.prefixPicker.selectedCountry.dialCode) {
      this.prefissoSelezionato.dialCode = this.prefixPicker.selectedCountry.dialCode;
    }
  }

  @HostListener('document:click', ['$event'])
  clickoutTimezone(event) {

    let timezoneElement = document.getElementById('timezone');
    let prefissoElement = document.getElementById('prefisso');

    if (timezoneElement != event.target) {
      this.openChangeTimezone = false;
    }

    if (prefissoElement != event.target) {
      this.openPrefisso = false;
    }
  }


  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {

    let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.targetTimezoneName = tz;
    this.gmt = TimezoneUtils.calculateGMT(tz);

    this.smartCheckFormMobile.addControl('tel', new FormControl('', Validators.required));
    this.smartCheckFormMobile.addControl('whatsapp', new FormControl());
    this.smartCheckFormMobile.addControl('prefisso', new FormControl(''));

    // this.route.url.subscribe( url => {
    //   console.log(url.)
    //   console.log(decodeURI(url));
    // })
    let href = this.router.url;
    let url = window.location.href;
    url = url.split('?')[1];
    url = url ? url : "";
    url = url.replace(/%3d/g, "=");
    url = url.replace(/%3D/g, "=");
    url = url.replace(/%26/g, "&");
    let urlParams = new URLSearchParams(url);

    let country = urlParams.get('Country');
    if (!country) {
      country = environment.defaultCountry;
    }
    this.apiEndPointService.setEndpointUri(country);

    // urlParams.forEach((value, key) => { console.log(key, value) });

    // this.route.queryParams
    //   .subscribe(params => {

    //     // this.resNo = params.ResNo;
    //     // this.email = params.Email;
    //     // this.startDate = new Date(params.StartDate);
    //     this.pickUp = params.PickUp;
    let brand = environment.brand[window.location.hostname];

    //     // var startDate: string = params.StartDate;
    //     // startDate = encodeURIComponent(startDate);
    // this.scheduleService.getSchedule(params.ResNo, params.StartDate, params.Email, params.PickUp, params.Language, brand)
    let language: string = urlParams.get("Language")
    if (language) {
      language = language.substr(0, 2);
    }
    this.scheduleService.getSchedule(urlParams.get("ResNo"), urlParams.get("StartDate"), urlParams.get("Email"), urlParams.get("PickUp"), language, brand, country)
      .subscribe((res: HttpResponse<any>) => {

        if (res.body.status == 'ANNULLATO') {
          this.router.navigate(['/smartcheckout-delete']);
        }

        if (res.body.status == 'IN_ATTESA') {
          this.liveSchedule = true;
        }
        let token = res.headers.get('Avis-Sig');
        localStorage.setItem("Avis-Sig", token);

        this.isCreazione = false;
        this.schedule = res.body;
        this.schedule.timezone = this.targetTimezoneName;

        this.whatsapp = res.body.reservation.whatsapp;
        this.prefissoSelezionato.iso2 = res.body.reservation.phonePrefixIso;
        this.startDate = new Date(this.schedule.appointmentDate)
        this.startTime = this.schedule.appointmentTime;
        this.smartCheckFormMobile.get('tel').setValue(this.schedule.reservation.phone);

        this.setScheduleInfo();

      },
        error => {
          let token = error.headers.get('Avis-Sig');
          localStorage.setItem("Avis-Sig", token);

          if (error.status == 404) {
            this.isCreazione = true;
            this.schedule = error.error
            this.schedule.timezone = this.targetTimezoneName;

            let pickupDate = new Date(this.schedule.reservation.pickDate);
            let pickDay = new Date(this.schedule.reservation.pickDate);
            pickDay.setHours(0, 0, 0, 0);
            if (this.today.getTime() >= pickDay.getTime()) {
              this.liveClickable = false;
              this.liveSchedule = true;

              // Controllo se il giorno è precedente di oggi
              if (this.today.getDate() != pickupDate.getDate()) {
                this.router.navigate(['/smartcheckout-non-trovata', { errorType: "1" }]);
                return;
              }
            }
            let dif = new Date(this.schedule.reservation.pickDate).getTime() - this.today.getTime();
            let hour = (dif / (1000 * 60 * 60));
            if (hour > 48) {
              this.liveHidden = true;
            }
            this.setScheduleInfo();

          } else {
            this.router.navigate(['/smartcheckout-non-trovata']);
          }
        }

      );
    // }
    // );


    if (window.innerWidth < 576) { // 768px portrait
      this.mobile = true;
    }
  }


  setScheduleInfo() {
    this.resNo = this.schedule.reservation.codeCompany;
    this.email = this.schedule.reservation.email;
    this.pickDate = new Date(this.schedule.reservation.pickDate);
    // this.pickDate = TimezoneUtils.convertDateTimezone(this.schedule.reservation.pickDate.split('T')[0], this.schedule.reservation.pickDate.split('T')[1], this.targetTimezoneName, environment.originTimezone).toDate();

  }
  openDiaogConfirm() {
    let host = window.location.hostname;

    this.schedule.reservation.phonePrefix = this.prefissoSelezionato.dialCode;
    this.schedule.reservation.phonePrefixIso = this.prefissoSelezionato.iso2;

    this.schedule.reservation.phone = this.smartCheckFormMobile.get('tel').value;
    this.schedule.reservation.whatsapp = this.smartCheckFormMobile.get('whatsapp').value;
    this.schedule.reservation.brand = { 'code': environment.brand[host] }

    this.schedule.language = { locale: this.stateService.language + '_' + this.stateService.language.toUpperCase() };

    let dialogRef = this.dialog.open(DialogConfermaComponent, {
      minWidth: 350.2,
      maxWidth: 350.2,
      maxHeight: 550.2,
      data: { startDate: this.startDate, email: this.email, phone: this.schedule.reservation.phone, liveSchedule: this.liveSchedule, startTime: this.startTime, resNo: this.resNo, prefisso: this.prefissoSelezionato.dialCode, gmt: this.gmt, targetTimeZoneName: this.targetTimezoneName, locale: this.locale }
    });

    dialogRef.afterClosed().subscribe(navigationExtras => {
      if (navigationExtras) {
        if (this.liveSchedule == true) {
          this.schedule.reservation.brand = { 'code': environment.brand[host] }
          this.scheduleService.createLiveAppointment(this.schedule)
            .subscribe(
              res => {
                this.router.navigate(['/smartcheckout'], navigationExtras);
              },
              err => {
                this.router.navigate(['/smartcheckout-non-trovata', { errorType: "2" }]);
              }
            );
        } else {

          let convertedDate = TimezoneUtils.convertDateTimezone(this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.startTime, this.targetTimezoneName, environment.originTimezone);
          this.schedule.appointmentDate = convertedDate.format('yyyy-MM-DD');
          this.schedule.appointmentTime = convertedDate.format('HH:mm:ss');

          this.scheduleService.createAppointment(this.schedule)
            .subscribe(
              res => {
                this.router.navigate(['/smartcheckout'], navigationExtras);
              },
              err => {
                this.router.navigate(['/smartcheckout-non-trovata', { errorType: "2" }]);
              }
            );
        }
      }
    });

    firebase.analytics().logEvent('click_mobile_prenotazione_new');

  }

  openDiaogEdit() {
    this.schedule.reservation.phonePrefix = this.prefissoSelezionato.dialCode;
    this.schedule.reservation.phonePrefixIso = this.prefissoSelezionato.iso2;

    this.schedule.reservation.phone = this.smartCheckFormMobile.get('tel').value;
    this.schedule.reservation.whatsapp = this.smartCheckFormMobile.get('whatsapp').value;
    this.schedule.language = { locale: this.stateService.language + '_' + this.stateService.language.toUpperCase() };

    let dialogRef = this.dialog.open(DialogConfermaComponent, {
      minWidth: 350.2,
      maxWidth: 350.2,
      maxHeight: 550.2,
      data: { startDate: this.startDate, email: this.email, phone: this.schedule.reservation.phone, liveSchedule: this.liveSchedule, startTime: this.startTime, resNo: this.resNo, prefisso: this.prefissoSelezionato.dialCode, gmt: this.gmt, targetTimeZoneName: this.targetTimezoneName, locale: this.locale }
    });

    dialogRef.afterClosed().subscribe(navigationExtras => {
      if (navigationExtras) {
        if (this.liveSchedule == true) {
          this.scheduleService.createLiveAppointment(this.schedule)
            .subscribe(
              res => {
                this.router.navigate(['/smartcheckout'], navigationExtras);
              },
              err => {
                this.router.navigate(['/smartcheckout-non-trovata', { errorType: "2" }]);
              }
            );
        } else {
          let convertedDate = TimezoneUtils.convertDateTimezone(this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.startTime, this.targetTimezoneName, environment.originTimezone);
          this.schedule.appointmentDate = convertedDate.format('yyyy-MM-DD');
          this.schedule.appointmentTime = convertedDate.format('HH:mm:ss');

          this.scheduleService.editAppointment(this.schedule)
            .subscribe(
              res => {
                this.router.navigate(['/smartcheckout'], navigationExtras);
              },
              err => {
                this.router.navigate(['/smartcheckout-non-trovata', { errorType: "2" }]);
              }
            );
        }
      }
    });
    firebase.analytics().logEvent('click_mobile_prenotazione_edit');

  }

  openDiaogDelete(idSchedule: number) {
    let dialogRef = this.dialog.open(DialogDeleteComponent, {
      minWidth: 350.2,
      maxWidth: 350.2,
      maxHeight: 550.2,
      panelClass: 'modalDelete'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAppointment(idSchedule)
      }
    });
  }

  deleteAppointment(idSchedule: number) {

    this.scheduleService.deleteAppointment(idSchedule).subscribe(res => {
    })
    firebase.analytics().logEvent('click_mobile_prenotazione_delete');

  }

  selectPrefisso(prefisso) {
    this.prefissoSelezionato = prefisso;
    this.openPrefisso = false;
  }

  changed(value: boolean) {
    this.liveSchedule = value;
  }
  changedWhatsapp(value: boolean) {
    this.whatsapp = value;
  }

  changeDate(item: Date) {
    this.startDate = item;
  }

  changeTime(item: string) {
    this.startTime = item;
  }


  cancelDate() {
    this.startDate = null;
    this.startTime = null;
  }

  openAccordion() {
    this.accordion = !this.accordion;
  }

  setClickable(item: boolean) {
    this.liveClickable = item;
  }

  gmtValue(item: number) {
    this.gmt = item;
  }
  timezoneNameValue(item: string) {
    this.targetTimezoneName = item;
  }

  setTimezoneName(item: string) {
    this.gmt = TimezoneUtils.calculateGMT(item);
    this.targetTimezoneName = item;
    this.openChangeTimezone = false;
    this.schedule.timezone = this.targetTimezoneName;
  }

  calculateGmt(item: string) {
    return TimezoneUtils.calculateGMT(item);
  }


  // convertDateTimezone(day: string, time: string, fromTimezone: string, toTimezone: string) {

  //   let momentOrigin = moment.tz(day + ' ' + time, fromTimezone);
  //   let momentTarget = momentOrigin.clone().tz(toTimezone);

  //   return momentTarget;
  // }
}
