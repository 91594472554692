import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Schedule } from '../model/schedule';
import { PickUpPoint } from '../model/pick-up-point';
import { StateService } from './stateService.service';
import {ApiEndPointService} from "./api-end-point.service";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  private endpointUri;

  constructor(
    private httpClient: HttpClient,
    private stateService : StateService,
    private apiEndPointService: ApiEndPointService,
  ) {
    this.apiEndPointService.getEndpointUri().subscribe( endopint => {
      this.endpointUri = endopint.uri;
      console.log('this.endpointUri', this.endpointUri)
    })
  }

  scheduleUrl = 'api/customerreservation/';


  getSchedule(ResNo: string, StartDate: string, Email: string, PickUp: string, Language: string, Brand:string, Country: string) {
    // let params = {
    //   ResNo,
    //   StartDate,
    //   Email,
    //   PickUp,
    //   Language,
    //   Brand
    // }
    ResNo= encodeURIComponent(ResNo);
    StartDate = encodeURIComponent(StartDate);
    Email = encodeURIComponent(Email);
    PickUp = encodeURIComponent(PickUp);
    Language = encodeURIComponent(Language);
    Brand = encodeURIComponent(Brand);
    Country = encodeURIComponent(Country);
    let url = `${this.endpointUri}${this.scheduleUrl}getSchedule?ResNo=${ResNo}&StartDate=${StartDate}&Email=${Email}&PickUp=${PickUp}&Language=${Language}&Brand=${Brand}&Country=${Country}`
    return this.httpClient.get<any>(url, { observe: 'response'});
    // return this.httpClient.get<any>(this.endpointUri + this.scheduleUrl + "getSchedule", { observe: 'response', params: params });
  }

  getSlotsForDate(serviceRoomId: string, companyCode: string, day: string) {

    let params = {
      serviceRoomId,
      companyCode,
      day,
      timeUrlIntegration: "true"
    }
    return this.httpClient.get(this.endpointUri + this.scheduleUrl + "slotsForDate", { params: params, headers: { 'Avis-Sig': localStorage.getItem('Avis-Sig') } });
  }

  getPickUp(code: string) {

    let params = {
      code
    }
    return this.httpClient.get(this.endpointUri + this.scheduleUrl + "pickupPoint", { params: params, headers: { 'Avis-Sig': localStorage.getItem('Avis-Sig') } });
  }

  createAppointment(schedule: Schedule) {
    let url = this.endpointUri + this.scheduleUrl + 'schedule'
    return this.httpClient.post(url, schedule, { headers: { 'Avis-Sig': localStorage.getItem('Avis-Sig') } });
  }

  createLiveAppointment(schedule: Schedule) {
    let url = this.endpointUri + this.scheduleUrl + 'saveLiveReservationSchedule'
    return this.httpClient.post(url, schedule, { headers: { 'Avis-Sig': localStorage.getItem('Avis-Sig') } });
  }

  editAppointment(schedule: Schedule) {
    let url = this.endpointUri + this.scheduleUrl + 'saveReservationSchedule'
    return this.httpClient.post(url, schedule, { headers: { 'Avis-Sig': localStorage.getItem('Avis-Sig') } });
  }

  deleteAppointment(scheduleId) {

    let url = this.endpointUri + this.scheduleUrl + 'cancelSchedule'

    let language = this.stateService.language + '_' + this.stateService.language.toUpperCase();
    let params = {
      scheduleId,
      language
    }
    return this.httpClient.delete(url, { params: params, headers: { 'Avis-Sig': localStorage.getItem('Avis-Sig') } });
  }

  testLink(ResNo: string, StartDate: string, Email: string, PickUp: string, Language: string, Brand: string, Country: string) {
    let url = this.endpointUri + this.scheduleUrl + 'testLink';

    //var Language = Language.split("_")[0];
    let params = {
      ResNo,
      StartDate,
      Email,
      PickUp,
      Language,
      Brand,
      Country,
    }
    return this.httpClient.get(url, { params: params });
  }

  getAllPickUpPoints(){
    let url = this.endpointUri + this.scheduleUrl + 'test/getPickupPoints';
    return this.httpClient.get<PickUpPoint[]>(url);
  }

}
