import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Schedule } from 'src/app/model/schedule';

@Component({
  selector: 'app-dialog-conferma',
  templateUrl: './dialog-conferma.component.html',
  styleUrls: ['./dialog-conferma.component.scss']
})
export class DialogConfermaComponent implements OnInit {

  //INIZIO TRADUZIONI
  title1: string = 'dialogConferma.title1';
  title2: string = 'dialogConferma.title2';
  dateTrad: string = 'dialogConferma.date';
  timeTrad: string = 'dialogConferma.time';
  dateTrad2: string = 'dialogConferma.date2';
  emailTrad: string = 'dialogConferma.email';
  numbPhone: string = 'dialogConferma.numbPhone';
  button1: string = 'dialogConferma.button1';
  button2: string = 'dialogConferma.button2';
  timeZone: string = 'dialogConferma.timeZone';

  //FINE TRADUZIONI

  date: Date;
  email: string;
  phone: string;
  time: string;
  liveSchedule: boolean;
  resNo: string;
  prefisso: string;
  schedule: Schedule = new Schedule();
  gmt: number;
  targetTimeZoneName:string;
  locale: string = "it-IT";

  constructor(public dialogRef: MatDialogRef<DialogConfermaComponent>, private router: Router, private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.date = this.data.startDate;
    this.email = this.data.email;
    this.phone = this.data.phone;
    this.liveSchedule = this.data.liveSchedule
    this.time = this.data.startTime;
    this.resNo = this.data.resNo;
    this.prefisso = this.data.prefisso;
    this.gmt = this.data.gmt;
    this.targetTimeZoneName = this.data.targetTimeZoneName;
    this.locale = this.data.locale;
  }

  chiudiModal() {
    this.dialogRef.close(false);
  }

  conferma() {
    let navigationExtras =
    {
      queryParams: {
        date: this.datePipe.transform(this.date, "yyyy-MM-dd"),
        // date : this.date.toString(),
        email: this.email,
        phone: this.phone,
        liveSchedule: this.liveSchedule,
        time: this.time,
        resNo: this.resNo,
        prefisso: this.prefisso,
        gmt: this.gmt,
        targetTimeZoneName: this.targetTimeZoneName,
        locale: this.locale
      },
      skipLocationChange: true
    };

    this.dialogRef.close(navigationExtras);
    //this.router.navigate(['/smartcheckout'], navigationExtras);

  }

}
