<div class="row">
    <div class="col">
        <span class="font-25">
            {{title1 | translate}}
        </span>

        <span class="primary-color bold font-25">
            {{email}}
        </span>
        <span class="font-25">
            {{title2 | translate}}
        </span>
    </div>
</div>

<div class="row m-t-55">
    <div class="col-md-3 offset-md-6 col">
        <button type="submit" class="standard-form__submit button primary-background" (click)="conferma()">
            {{button1 | translate}}</button>
    </div>
    <div class="col-md-3 col">
        <button class="annullaButton" (click)="chiudiModal()">  {{button2 | translate}}</button>

    </div>
</div>