<div class="row m-0">
    <!-- <div class="col-md-1   col-12 p-0 flex-end-x m-r-20 ">
        <img src="./assets/images/error.svg">
    </div> -->
    <div class="col font-30  font-size-20 bold p-0 family-Prompt">
        <span class="colorWhite">
            {{title1 | translate}} <br>
            {{title2 | translate}}

        </span>
    </div>
</div>

<div class="row m-t-20">
    <div class="col family-Prompt font-16">
        <span class="colorWhite">
            {{subtitle | translate}}
        </span>

    </div>
</div>

<div class="row m-t-55">
    <div class="col-md-3 offset-md-6 col">
        <button type="submit" class="standard-form__submit button primary-background" (click)="conferma()">
            {{yes | translate}}</button>
    </div>
    <div class="col-md-3 col">
        <button class="annullaButton" (click)="chiudiModal()"> {{no | translate}}</button>

    </div>
</div>