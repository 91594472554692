import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './feature/home/home.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CardPrenotazioneComponent } from './feature/home/components/card-prenotazione/card-prenotazione.component';
import { DayComponent } from './shared/components/day/day.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SmartcheckoutComponent } from './feature/components/smartcheckout/smartcheckout.component';
import { MatDividerModule } from '@angular/material/divider';
import { FourActionComponent } from './shared/components/four-action/four-action.component';
import { DialogConfermaComponent } from './feature/components/dialog-conferma/dialog-conferma.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ChangeLanguageComponent } from './shared/components/change-language/change-language.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BrandComponent } from './feature/brand/brand.component';
import { DialogDeleteComponent } from './feature/components/dialog-delete/dialog-delete.component';
import { SmartcheckoutDeleteComponent } from './feature/components/smartcheckout-delete/smartcheckout-delete.component';
import { NewCalendarComponent } from './shared/components/new-calendar/new-calendar.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { ModalTestComponent } from './feature/brand/components/modal-test/modal-test.component';
import { SmartechkoutPrenotazioneNonTrovataComponent } from './feature/components/smartechkout-prenotazione-non-trovata/smartechkout-prenotazione-non-trovata.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HTTPStatus, InterceptorService } from './service/interceptor.service';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NgxTimezonesModule } from 'ngx-timezones';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

/** LOCALE **/
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import it from '@angular/common/locales/it';
import es from '@angular/common/locales/es';
import fr from '@angular/common/locales/fr';
import de from '@angular/common/locales/de';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
registerLocaleData(it);
registerLocaleData(es);
registerLocaleData(fr);
registerLocaleData(de);

export function createTranslateLoader(http: HttpClient) {

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getLocale(translateService: TranslateService) {
  // translateService.onLangChange.subscribe( x => {
  //   var currentLang = x.lang.substr(0,2);
  //   currentLang = currentLang + "-" + currentLang.toUpperCase();
  //   return currentLang;
  // });
  return 'it-IT';
}

const MODULE_MATERIAL = [
  MatButtonModule,
  MatToolbarModule,
  MatDividerModule,
  MatDialogModule,
  MatIconModule,
  MatChipsModule,
  MatCardModule,
  MatDatepickerModule,
  MatInputModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatProgressSpinnerModule
];
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CardPrenotazioneComponent,
    DayComponent,
    SmartcheckoutComponent,
    FourActionComponent,
    DialogConfermaComponent,
    ChangeLanguageComponent,
    BrandComponent,
    DialogDeleteComponent,
    SmartcheckoutDeleteComponent,
    NewCalendarComponent,
    ModalTestComponent,
    SmartechkoutPrenotazioneNonTrovataComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxTimezonesModule,
    NgxMatSelectSearchModule,
    NgxIntlTelInputModule,
    TranslateModule.forRoot({
      defaultLanguage: 'enAVIS',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MODULE_MATERIAL
  ],
  exports: [DialogConfermaComponent, DialogDeleteComponent, ModalTestComponent],
  providers: [DatePipe,
    InterceptorService,
    HTTPStatus,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useFactory: getLocale, deps: [TranslateService] }, //your locale
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
