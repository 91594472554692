<app-change-language [languageDefault]="languageDefault"></app-change-language>

<div class="header__row header__row--main primary-background"   *ngIf="brand != 'BUDGET'">
  <div class="l-container l-container--12">
    <div class="l-col l-col--12">
      <a class="header__logo">
        <img src="../assets/images/avis/logo-white.svg" alt="Avis" *ngIf="brand == 'AVIS'"></a>
    </div>
    <div>
      <a class="header__logo">
        <img src="../assets/images/maggiore/logo-white.svg" alt="Avis" *ngIf="brand == 'MAGGIORE'"></a>
    </div>

  </div>
</div>

<div class="header__row header__row--main" style="background-color: white;"  *ngIf="brand == 'BUDGET'">
  <div class="l-container l-container--12">

    <div> 
      <a class="header__logo" >
        <img src="../assets/images/budget/budget-logo.svg"  *ngIf="brand == 'BUDGET'"></a>
    </div>
  </div>
</div>



<router-outlet></router-outlet>

<app-footer></app-footer>

<div *ngIf="HTTPActivity" class="loading">
  <!-- <img style="width: 50px" src='assets/images/maggiore/logo-white.svg'> -->
  <mat-spinner></mat-spinner>

</div>