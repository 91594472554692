// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //comando per farlo partire con il file host cambiato
  // ng serve --open --host $IP --port $PORT --disable-host-check

  // brand:{'AVIS':1,'MAGGIORE':2, 'BUDGET':3},

  // targetTimezone : Intl.DateTimeFormat().resolvedOptions().timeZone,

  originTimezone: 'Europe/Rome',
  idCompany: { 'AVIS': 1, 'MAGGIORE': 2},
  theme: { 'AVIS': 'AVIS', 'BUDGET': 'BUDGET', 'MAGGIORE': 'MAGGIORE', },
  brandId: {
    'smartcheckout-integration.dstech.info': 0,
    'budget-integration.dstech.info': 1,
    'maggiore-integration.dstech.info': 2,
    'localhost': 0,
  },
  brand: {
    'smartcheckout-integration.dstech.info': 'AVIS',
    'budget-integration.dstech.info': 'BUDGET',
    'maggiore-integration.dstech.info': 'MAGGIORE',
    'localhost': 'AVIS',
  },
  // brandId: { 'localhost': 0, 'budget-integration.dstech.info': 1, 'maggiore-integration.dstech.info': 2, },
  // brand: { 'localhost': 'AVIS', 'budget-integration.dstech.info': 'BUDGET', 'maggiore-integration.dstech.info': 'MAGGIORE', },

  defaultCountry: 'IT',

  endpointUriArray: [
    {country: 'it', uri: 'https://smart-checkout-test.dstech.info/'},
    {country: 'es', uri: 'https://smart-checkout-test-es.dstech.info/'},
    {country: 'pt', uri: 'https://smart-checkout-test-es.dstech.info/'},
    {country: 'de', uri: 'https://smart-checkout-uat-de.dstech.info/'},
    {country: 'gb', uri: 'https://smart-checkout-uat-uk.dstech.info/'},
    {country: 'fr', uri: 'https://smart-checkout-uat-fr.dstech.info/'},
  ],
  // endpointUri: 'https://smart-checkout-test.dstech.info/',   //url test
  // endpointUri: 'http://localhost:8081/',   //url test
  // endpointUri: 'http://19b16f3c1698.ngrok.io/',   //url puntando a marco

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
