<div class="row m-0 text-center p-t-30">
    <div class="col">
        <img src="./assets/images/error.svg">

    </div>
</div>

<div class="row m-0">
    <div class="col text-center font-30 bold color-red font-size-20">
        {{title1 | translate}}
    </div>
</div>

<div class="row m-0">
    <!-- Errore generica -->
    <div class="col text-center font-30 bold color-red font-size-20 m-t-25" *ngIf="errorType == '0'">
        {{title2 | translate}} 
    </div>
    
    <!-- Errore data scaduta -->
    <div class="col text-center font-30 bold color-red font-size-20 m-t-25" *ngIf="errorType == '1'">
        {{title3 | translate}}
    </div>
    
    <!-- Errore prenotazione fallita -->
    <div class="col text-center font-30 bold color-red font-size-20 m-t-25" *ngIf="errorType == '2'">
        {{title4 | translate}}
    </div>
</div>


<div class="row m-0" style="margin-bottom: 450px">
    <div class="col text-center font-18 bold m-t-20 m-b-20 font-size-16">
        {{subtitle | translate}}
    </div>
</div>