<div class="row m-0">
  <div class="col font-black p-0">

    <mat-card class=" border-radius">

      <form [formGroup]="smartCheckForm">

        <div class="row  m-0 p-b-20">
          <div class="col-6 font-16 bold primary-color m-b-5 p-0">
            {{numRes | translate}}
          </div>
          <div class="col font-16 p-0">
            {{resNo}}
          </div>
        </div>

        <div class="row p-b-10">
          <div class="col font-16">
            <span>
              {{yourPickUp | translate}}
            </span>
            <span class="bold">
              {{schedule?.reservation.pickDate  | date: 'dd MMM yyyy HH:mm' : '' : locale}}
            </span>
          </div>
        </div>

        <div class="row  m-0 p-b-10">
          <div class="col-5  font-16 normal-bold  p-0 justify-content-center align-self-center">
            <span>
              {{timeZoneTitle | translate}}
              <!-- {{gmt >= 0 ? '+' : ''}}{{gmt}} -->
            </span>

          </div>

          <div class="col-7 p-0">
            <ngx-timezones class="standard-form__input standard-form__select selectTimezone " [placeholder]="''"
              [guess]="true" [timezone]="targetTimezoneName" [showOffset]="true" offsetName="GMT"
              (timezoneChange)="setTimezoneName($event)">
            </ngx-timezones>
            <!-- <div class="row m-0">

              <div class="standard-form__input standard-form__select selectTimezone"
                [ngClass]="{'borderDiv' : openChangeTimezone}" id='timezone'
                (click)="openChangeTimezone = !openChangeTimezone">
                <span class="lineheightTimezone">
                  {{targetTimezoneName?.replace('_', ' ').replace('/', ' / ')  }}
                </span>
                <span style="margin-left: auto;">
                  (GMT {{gmt >= 0 ? '+' : ''}}{{gmt}})
                </span>
              </div>
            </div> -->
            <!-- <div class="row m-0 divSopra" *ngIf="openChangeTimezone">
              <div class="col divTimepicker">
                <span class="p-0 ui-timepicker-wrapper  bg-grey-chiaro font-15 d-flex"
                  *ngFor="let timezone of timezoneList" (click)="setTimezoneName(timezone)">
                  <span>
                    {{timezone.replaceAll('_', ' ').replaceAll('/', ' / ')}}
                  </span>
                  <span style="margin-left: auto; min-width: 30%;">
                    (GMT {{calculateGmt(timezone) >= 0 ? '+' : ''}}{{calculateGmt(timezone) }})
                  </span>
                </span>

              </div>
            </div> -->
          </div>
        </div>

        <div class="row m-0" *ngIf="!isCreazione">
          <div class="col-md-12 font-16 p-0 m-b-15 ">
            {{subtitleEdit | translate}}
          </div>
        </div>

        <div class="row m-0">
          <div class="col p-0 ">
            <app-new-calendar [liveSchedule]="liveSchedule" [day]="pickDate" [schedule]="schedule"
              (newDate)="changeDate($event)" (newTime)="changeTime($event)" (liveClickable)="setClickable($event)"
              [gmt]="gmt" [targetTimezoneName]="targetTimezoneName" (cancelDate)="cancelDate()">
            </app-new-calendar>
          </div>
        </div>

        <div class="row m-0" *ngIf="!liveHidden">
          <div class="col p-0">
            <ul class="option-list">
              <li class="option-list__item">
                <input type="checkbox" name="trigger-return-location" id="trigger-return-location"
                  [checked]="liveSchedule" [disabled]="!liveClickable" value="{{liveSchedule}}"
                  (change)="changed($event.target.checked)">
                <label class="option-list__control font-15 color-subtitle" for="trigger-return-location">
                  {{callPossible | translate}}
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="row m-0 ">
          <div class="col p-0 m-t-15">
            <label class="standard-form__label font-12 " for="">{{insertEmail | translate}}</label>
            <span class="font-16">
              {{email}}
            </span>
            <!-- <input type="text" class="standard-form__input" style="color: #8F8F8F;" name="email" id="email"
              value="{{email}}" readonly> -->
          </div>
        </div>


        <div class="row">
          <div class="col m-t-15">
            <label class="standard-form__label font-12" for="">{{insertPhone | translate}}</label>
          </div>
        </div>
        <div class="row m-0 ">
          <div class="col-2 p-0">
               <div class="standard-form__input p-0">
                <ngx-intl-tel-input [cssClass]="'standard-form__input custom '" [preferredCountries]="preferredCountries"
                [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name,SearchCountryField.DialCode]" [selectFirstCountry]="false"
                [selectedCountryISO]="prefissoSelezionato.iso2" (countryChange)="selectPrefisso($event)"
                [phoneValidation]="true" [separateDialCode]="true" name="prefisso" formControlName="prefisso">
              </ngx-intl-tel-input>
              </div> 
           
            <!-- <div class="row m-0">
              <div class="standard-form__input standard-form__select" [ngClass]="{'borderDiv' : openPrefisso}"
                id="prefisso" (click)="openPrefisso = !openPrefisso">
                <span class="centerVerticalText">
                  {{prefissoSelezionato }}
                </span>
              </div>
            </div>
            <div class="row m-0 divSopra" *ngIf="openPrefisso">
              <div class="col divTimepicker">
                <span class="p-0 ui-timepicker-wrapper flex-start-x bg-grey-chiaro font-15"
                  *ngFor="let prefisso of prefissoList" (click)="selectPrefisso(prefisso)">
                  {{prefisso}}
                </span>
              </div>
            </div> -->
          </div>
          <div class="col m-l-10 p-0">
            <input type="number" class="standard-form__input" name="" id="tel"  formControlName="tel" 
              value="{{schedule?.reservation.phone}}">
          </div>
        </div>

        <div class="row m-0" *ngIf="nameEnviroment != 'BUDGET'">
          <div class="col p-0  m-t-10">
            <ul class="option-list">
              <li class="option-list__item">
                <input type="checkbox" name="checkbox-item-1" id="checkbox-item-1" value="{{whatsapp}}"
                  (change)="changedWhatsapp($event.target.checked)" formControlName="whatsapp">
                <label class="option-list__control font-15 color-subtitle"
                  for="checkbox-item-1">{{numbWhatsapp | translate}}</label>
              </li>
            </ul>
          </div>
        </div>

        <div class="row m-t-15" *ngIf="isCreazione">
          <div class="col flex-center m-t-20">
            <button type="submit" class="p-0 standard-form__submit button primary-background font-12"
              [disabled]="smartCheckForm.invalid || ((!startDate || !startTime) && !liveSchedule)" (click)="openDiaogConfirm()">{{button1 | translate}}</button>
          </div>

        </div>

        <div class="row " *ngIf="!isCreazione">
          <div class="col-md-6 col-12  flex-center m-t-20">
            <button type="submit" [disabled]="smartCheckForm.invalid || ((!startDate || !startTime) && !liveSchedule)"
              class="p-0 standard-form__submit button primary-background font-12"
              (click)="openDiaogEdit()">{{button2 | translate}}</button>
          </div>
          <div class="col-md-6 col-12  flex-center m-t-20">
            <button type="submit" class="p-0 standard-form__submit annullaButton primary-background font-12"
              (click)="openDiaogDelete(schedule.id)">{{button3 | translate}}</button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</div>
