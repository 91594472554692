import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HTTPStatus, InterceptorService } from './service/interceptor.service';
import { ScheduleService } from './service/schedule.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  primaryColor: string;
  secondaryColor: string;
  english: boolean = false;
  italian: boolean = true;
  company: string = 'AVIS';
  isCreazione: boolean;
  languageDefault: string;
  HTTPActivity: boolean = false;
  brand: string;

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  
  constructor(private route: ActivatedRoute, private scheduleService: ScheduleService, private translateService: TranslateService,
    private interceptorService: InterceptorService, private httpStatus: HTTPStatus, private title: Title) {
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.HTTPActivity = status;
    });

    // let host: string = 'smartcheckout-integration.dstech.info';
    this.languageDefault = navigator.language || navigator['userLanguage'];

    if (this.languageDefault.indexOf('it') != -1) {
      this.languageDefault = 'it';
    } else if (this.languageDefault.indexOf('en') != -1) {
      this.languageDefault = 'en';
    }

    //altri if con le altre lingue che ci andranno

    let host = window.location.hostname;
    this.brand = environment.brand[host]
    this.changeTheme(environment.theme[this.brand]); // Set default theme

    /** DYNAMIC FAVICON **/
    let faviconPath = 'https://avisassets.abgemea.com/.resources/avis-digital/img/favicons/favicon.ico';
    let titleName = 'titleBar';
    switch (this.brand) {
      case "AVIS":
        faviconPath = 'https://avisassets.abgemea.com/.resources/avis-digital/img/favicons/favicon.ico';
        titleName = 'titleBar';
        break;
      case "MAGGIORE":
        faviconPath = 'https://www.maggiore.it/web/img/favicon.ico?v=1.1';
        titleName = 'titleBar';

        break;
      case "BUDGET":
        faviconPath = 'https://budgetassets.abgemea.com/.resources/digital/img/favicon.ico';
        titleName = 'titleBar';

        break;
    }
    this.favIcon.href = faviconPath;
    translateService.onLangChange.subscribe(res => {
      translateService.get(titleName).subscribe((res: string) => {
        title.setTitle(res);
      });
    }) 
   
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(displayLocationInfo);
    }
    
    function displayLocationInfo(position) {
      const lng = position.coords.longitude;
      const lat = position.coords.latitude;
    
      console.log(`latitude: ${ lat } | longitude: ${ lng }`);
    }

  }

  changeTheme(brand?: string) {
    this.brand = brand;
    // let element = document.getElementById("logo") as HTMLImageElement;
    // element.src = './assets/images/avis-logo-bianco.svg';
    // let logoFooter =  document.getElementById("logoFooter") as HTMLImageElement;

    if (brand == 'MAGGIORE') {
      brand = 'MAGGIORE';
      document.documentElement.style.setProperty('--primary-color', '#02285f');
      // logoFooter.src = './assets/images/abg-logo.svg';

      // element.src = './assets/images/logo-maggiore-white.svg';
    } else if (brand == 'AVIS') {
      document.documentElement.style.setProperty('--primary-color', '#d4002a');
      // logoFooter.src = './assets/images/avi-logo-pallini.svg';

    } else if (brand == 'BUDGET') {
      document.documentElement.style.setProperty('--primary-color', '#0046a4');
      // logoFooter.src = './assets/images/budget/logo-white.svg';
    }
  }


}

