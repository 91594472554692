import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { StateService } from 'src/app/service/stateService.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent implements OnInit {

  //INIZIO TRADUZIONE
  titleLingua: string = 'componentLingua.titleLingua';

  //FINE TRADUZIONE

  @Input() languageDefault: string;

  company: string = 'AVIS';

  openLanguage: boolean = false;

  language: string = 'it';


  myForm: FormGroup = new FormGroup({});

  activeLanguage: string;

  mobile: boolean = false;

  // mappaPaesi = {

  //   Italia:
  //   {
  //     'Italiano': 'it'
  //   },
  //   United_Kingdom: {
  //     'English': 'en'
  //   },
  // Deutschland: {
  //   'Deutsch': 'de'
  // },
  // France: {
  //   'Français ': 'fr'
  // },

  // España: {
  //   'Español': 'es'
  // },

  // Svizzera:
  // {
  //   'Italiano': 'it',
  //   'Inglese': 'en',
  //   'Tedesco': 'de',
  // }

  // };


  mappaLingua = {

    'it': 'Italiano',
    'en': 'English',
    'de': 'Deutsch',
    'fr': 'Français',
    'es': 'Español'
  }

  paese: string;

  @HostListener('document:click', ['$event'])
  clickoutTimezone(event) {

    let languageElement = document.getElementById('changeLanguage')
    if (languageElement != event.target) {
      this.openLanguage = false;
    }
  }

  constructor(private translateService: TranslateService, private eRef: ElementRef,private stateService: StateService) { }

  ngOnInit(): void {


    if (window.innerWidth < 576) { // 768px portrait
      this.mobile = true;
    }
    this.languageDefault = this.languageDefault.substr(0,2);
    this.goLanguage(this.languageDefault);
    // this.myForm.addControl("paese", new FormControl(""));
    this.myForm.addControl("lingua", new FormControl("", Validators.required));

    // this.myForm
    //   .get("paese")
    //   .valueChanges.pipe(distinctUntilChanged())
    //   .subscribe(paeseSelezionato => {
    //     //preseleziono la prima lingua della lista del paese
    //     this.myForm.get("lingua").setValue(paeseSelezionato.value[0]);
    //   });

    // this.changeLanguage(this.languageDefault);

  }


  goLanguage(language: string) {
    this.language = language;
    this.activeLanguage = this.mappaLingua[language];
    if(this.activeLanguage) {
      this.translateService.use(language + this.company);
    } else {
      this.translateService.use("en" + this.company);
      this.activeLanguage = "English";
      this.language = "en";
    }

    this.stateService.language = language;   
    this.openLanguage = false;
    // this.changeLanguage(this.myForm.get('lingua').value);
  }


  // changeLanguage(language: string) {

  //   this.translateService.use(language + this.company);

  //   if (this.myForm.get('paese').value.key != undefined) {
  //     this.paese = this.myForm.get('paese').value.key.replaceAll('_', ' ');

  //   }
  //   this.openLanguage = false;
  // }

}
