<div class="row m-0 p-2 d-flex justify-content-end"
  style="background-color: #f1f1f1; padding-top: 4px !important; padding-bottom: 4px !important;">
  <!-- <button class="language-country-selector__toggle " (click)="openLanguage = !openLanguage">
      <i class="icon icon--globe"></i>
      <span>
        {{paese}}
      </span>
      <i class="icon icon--chevron-down" aria-hidden="true"></i>
    </button> -->

  <!-- <div>
      <span *ngFor="let currentLanguage of mappaLingua | keyvalue" (click)="goLanguage(currentLanguage.value)"
        class="p-r-15">
        <span [ngClass]="{'bold': currentLanguage.value == language}">
          <img src="./assets/images/flag-{{currentLanguage.value}}.png" class="flagSize">
        </span>
        <span [ngClass]="{'bold': currentLanguage.value == language}">
          {{currentLanguage.key}}

        </span>
      </span>
    </div> -->

  <div class="row m-0  d-flex justify-content-end " [ngClass]="{'full-width': mobile}">
    <div class="col d-flex justify-content-end" *ngIf="!mobile">
      <span style="margin-right: 5px;">
        <i class="icon icon--globe verticalTextLingua"></i>
      </span>

      <span class="verticalTextLingua">
        {{titleLingua | translate}}
      </span>

    </div>

    <div class="col d-flex justify-content-end p-0 language-picker">
      <div class="row    m-0 standard-form__input standard-form__select linguaDimensione altezzaSelectLingua"
        id="changeLanguage" [ngClass]="{'borderDiv' : openLanguage}" (click)="openLanguage = !openLanguage">
        <div class="col p-0">
          <span class="verticalTextLingua bold font-13">
            {{activeLanguage}} </span>
        </div>
        <div class="col  d-flex align-items-center justify-content-end">
          <img src="./assets/images/flag-{{language}}.png" class="flagSize">
        </div>
      </div>

      <div class="row m-0 divSopra language-list d-flex justify-content-end" *ngIf="openLanguage">
        <div class="col divTimepicker linguaDimensione">
          <div class="p-2  flex-start-x bg-grey-chiaro font-13" *ngFor="let currentLanguage of mappaLingua | keyvalue"
            (click)="goLanguage(currentLanguage.key)">
            <div class="row m-0 " style="width: 100%; padding-right: 22px;">
              <div class="col p-0">
                <div>
                  {{currentLanguage.value}}
                </div>
              </div>
              <div class="col  d-flex align-items-center justify-content-end">
                <div>
                  <img src="./assets/images/flag-{{currentLanguage.key}}.png" class="flagSize">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- <form [formGroup]=" myForm">
              <div class="row m-0 divSopra" id="chiudi" *ngIf="openLanguage ">
                <div class="col-md-10 offset-md-1 col-12 color-black bg-language">
                  <div class="row flex-center-y">
                    <div class="col-md-2 col-12">
                      <i class="icon icon--globe"></i> SELECT COUNTRY/ REGION
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="standard-form__select">
                        <select id="country-selector" name="country-selector" formControlName="paese">

                          <option [ngValue]="item" *ngFor="let item of mappaPaesi | keyvalue">
                            {{item.key.replaceAll('_', ' ')}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-5 col-12">
                      <div class="language-country-selector__col language-country-selector__col--last"
                        style="width: 100%;">
                        <label class="standard-form__label language-country-selector__heading"><i
                            class="icon icon--language" aria-hidden="true"></i> Select language</label>
                        <ng-container
                          *ngFor="let lingua of myForm.controls.paese.value.value  | keyvalue; let index = index">
                          <input formControlName="lingua" type="radio" [value]="lingua.value" required />
                          <label class=" p-r-30 p-l-20" for="lingua">{{lingua.key}}</label>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-md-1 col-12">

                      <div class="m-t-20 col-md-4 col-12 standard-form__actions ">
                        <button type="submit" class="standard-form__submit button primary-background"
                          (click)="goLanguage()" [disabled]="myForm.invalid">
                          GO </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              </form> -->