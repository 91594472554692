<div class="row m-0 text-center">
    <div class="col" style="margin-top: 120px;">
        <img src="./assets/images/check.svg">

    </div>
</div>


<div class="row m-0">
    <div class="col text-center font-30 bold color-green">
        {{title | translate}}
    </div>
</div>


<div class="row m-0">
    <div class="col text-center font-16 bold m-t-20 m-b-20 coloreLoading color-red" style="margin-bottom: 400px">
        {{subtitle | translate}}
    </div>
</div>


