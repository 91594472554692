<div class="row m-0 p-t-30 p-b-30">
    <div class="col bold font-35 text-center">
        Test Page
    </div>
</div>

<div class="row m-0 p-b-40">
    <div class="col  font-30 text-center">
        Through this page it is possible to simulate the arrival of the user from the three different URLs
    </div>
</div>

<div class="row m-0 p-b-50">
    <div class="col-md-8 offset-md-2">
        <mat-card class="m-t-35 " style="margin-bottom: 190px;">
            <form [formGroup]="smartCheckForm">
              <div class="row pt-15">

                <div class="col-md-4">
                  <div><small>Country</small></div>
                  <div class="standard-form__select">
                    <select formControlName="country" (ngModelChange)="changeCountry($event)">
                      <option *ngFor="let country of arrayCountry">
                        {{country}}</option>
                    </select>
                  </div>
                </div>

              </div>
                <div class="row">
                    <div class="col-md-4">
                      <div><small>Reservation Number</small></div>
                        <input class="standard-form__input" name="resNo" id="resNo" formControlName="resNo"
                            placeholder="Reservation Number">
                    </div>
                    <div class="col-md-4">
                      <div><small>startDate</small></div>
                        <mat-form-field appearance="fill" class="standard-form__input">
                            <input matInput [matDatepicker]="picker" placeholder="Pick Up Date" [min]="today"
                                formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <div><small>E-mail</small></div>
                        <input class="standard-form__input" name="email" id="email" formControlName="email"
                            placeholder="E-mail">
                    </div>
                </div>

                <div class="row p-15">
                    <div class="col-md-4 p-0 ">
                        <!-- <input class="standard-form__input" name="pickUp" id="pickUp" formControlName="pickUp"
                            placeholder="Pick Up Point Code (es:AVIS)"> -->
                      <div><small>Pick Up Point</small></div>
                        <div class="standard-form__select">
                            <select formControlName="pickUp">
                                <option *ngFor="let pickUpPoint of listaPickUpPoint" [ngValue]="pickUpPoint">
                                  {{pickUpPoint.code}} - {{pickUpPoint.companyCode}} - {{pickUpPoint.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-4 p-r-0">
                        <!-- <input class="standard-form__input" name="language" id="language" formControlName="language"
                            placeholder="Language"> -->
                      <div><small>Language</small></div>
                        <div class="standard-form__select">
                            <select formControlName="language">
                              <option *ngFor="let language of arrayLanguage">
                                {{language}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-4">
                      <div><small>startTime</small></div>
                            <input class="standard-form__input" name="startTime" id="startTime"
                                formControlName="startTime" placeholder="Start Time" type="time" step="2">
                    </div>

                </div>



                <div class="row m-l-30 m-t-45">
                    <div class="col-md-4 col">
                        <button type="button" (click)="openDialogTest('avis')" class="btn btn-info font-25"
                            [disabled]="smartCheckForm.invalid || smartCheckForm.controls.pickUp.value?.companyCode != 'AVIS'"
                            style="background-color: #d4002a;">Avis Booking</button>
                    </div>
                    <div class="col-md-4 col">
                        <button type="button" (click)="openDialogTest('budget')" class="btn btn-info font-25"
                            [disabled]="smartCheckForm.invalid || smartCheckForm.controls.pickUp.value?.companyCode != 'AVIS'"
                            style="background-color: #d4002a;">Budget
                            Booking</button>
                    </div>
                    <div class="col-md-4 col">
                        <button type="button" (click)="openDialogTest('maggiore')" class="btn btn-info font-25"
                            [disabled]="smartCheckForm.invalid || smartCheckForm.controls.pickUp.value?.companyCode != 'Maggiore'"
                            style="background-color: #02285f;">Maggiore
                            Booking</button>
                    </div>
                </div>

            </form>
        </mat-card>
    </div>
</div>
