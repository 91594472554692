import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PickUpPoint } from 'src/app/model/pick-up-point';
import { ScheduleService } from 'src/app/service/schedule.service';
import { environment } from 'src/environments/environment';
import { ModalTestComponent } from './components/modal-test/modal-test.component';
import {ApiEndPointService} from "../../service/api-end-point.service";

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private scheduleService: ScheduleService,
    private dialog: MatDialog,
    private apiEndPointService: ApiEndPointService
  ) { }

  resNo: string;
  startDate: Date;
  startTime: string;

  email: string;
  pickUp: string;
  language: string;
  country: string;

  regexpMail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
  smartCheckForm = new FormGroup({});
  arrayLanguage = ['it', 'en', 'fr', 'de', 'es']
  arrayCountry = Object.values(environment.endpointUriArray).map( item => item.country.toUpperCase())
  listaPickUpPoint: object[] = []

  today : Date = new Date()

  ngOnInit(): void {

    this.getAllPickUpPoints();

    this.smartCheckForm.addControl('resNo', new FormControl('', Validators.required));
    this.smartCheckForm.addControl('startDate', new FormControl('', Validators.required));
    this.smartCheckForm.addControl('startTime', new FormControl('', Validators.required));

    this.smartCheckForm.addControl('email', new FormControl('', [Validators.required, Validators.pattern(this.regexpMail)]));
    this.smartCheckForm.addControl('pickUp', new FormControl('', Validators.required));
    this.smartCheckForm.addControl('language', new FormControl('en', Validators.required));
    this.smartCheckForm.addControl('country', new FormControl('IT', Validators.required));

    this.smartCheckForm.valueChanges.subscribe(res => {
    })

  }


  setForm() {
    this.resNo = this.smartCheckForm.get('resNo').value;
    this.startDate = this.smartCheckForm.get('startDate').value ;
    this.startTime = this.smartCheckForm.get('startTime').value;
    this.email = this.smartCheckForm.get('email').value;
    this.pickUp = this.smartCheckForm.get('pickUp').value.code;
    this.language = this.smartCheckForm.get('language').value;
    this.country = this.smartCheckForm.get('country').value;

    this.startDate.setHours(Number( this.startTime.split(':')[0]),Number (this.startTime.split(':')[1]),Number(this.startTime.split(':')[2]));
  }

  openDialogTest(brand: string) {
    this.setForm();
    let dialogRef = this.dialog.open(ModalTestComponent, {
      minWidth: 730.2,
      maxWidth: 500.2,
      data: { email: this.email }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.scheduleService.testLink(this.resNo, this.datePipe.transform(this.startDate, "yyyy-MM-dd HH:mm:ss"), this.email, this.pickUp, this.language, brand.toUpperCase(), this.country).subscribe(res => {
          this.smartCheckForm.reset();
        })
      }
    });
  }

  getAllPickUpPoints() {
    this.listaPickUpPoint = [];
    this.scheduleService.getAllPickUpPoints().subscribe(res => {
      res.forEach(element => {
        let row = {}
        element.companyCodes.forEach(code => {
          row['companyCode'] = element.company.code;
          row['name'] = element.name;
          row['code'] = code;
          this.listaPickUpPoint.push(row)
        })
      });
    })

  }

  changeCountry(country) {
    this.apiEndPointService.setEndpointUri(country);
    this.getAllPickUpPoints();
  }
}
