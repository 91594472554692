import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
declare var firebase: any;

@Component({
  selector: 'app-smartcheckout',
  templateUrl: './smartcheckout.component.html',
  styleUrls: ['./smartcheckout.component.scss']
})
export class SmartcheckoutComponent implements OnInit {

  // INIZIO TRADUZIONI
  title1: string = 'pageRiepilogoConferma.title1';
  title2: string = 'pageRiepilogoConferma.title2';
  thank: string = 'pageRiepilogoConferma.thank';
  textDate: string = 'pageRiepilogoConferma.date';
  textTime: string = 'pageRiepilogoConferma.time';
  subtitle: string = 'pageRiepilogoConferma.subtitle';
  subtitleCanPossible: string = 'pageRiepilogoConferma.subtitleCanPossible'
  reservationNumber: string = 'pageRiepilogoConferma.reservationNumber';
  appointament: string = 'pageRiepilogoConferma.appointament';
  titleFooter: string = 'pageRiepilogoConferma.titleFooter';
  subtitleFooter1: string = 'pageRiepilogoConferma.subtitleFooter1';
  subtitleFooter2: string = 'pageRiepilogoConferma.subtitleFooter2';
  titleMobileNumber: string = 'pageRiepilogoConferma.titleMobileNumber';
  nextOne: string = 'pageRiepilogoConferma.nextOne';
  timeZone: string = 'dialogConferma.timeZone';
  // FINE TRADUZIONI

  date: Date;
  email: string;
  phone: string;
  time: string;
  resNo: string;
  liveSchedule: boolean;
  prefisso: string;
  gmt: number;
  targetTimeZoneName:string;
  locale: string = "it-IT";

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public translateService: TranslateService) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.date = new Date(params.date)
      this.email = params.email;
      this.resNo = params.resNo;
      this.liveSchedule = JSON.parse(params.liveSchedule);
      this.time = params.time;
      this.phone = params.phone;
      this.prefisso = params.prefisso;
      this.gmt = params.gmt;
      this.targetTimeZoneName = params.targetTimeZoneName
      this.locale = params.locale
    })

    
    this.translateService.onLangChange.subscribe(translation => {
      this.locale = translation.lang.substr(0,2);
      this.locale = this.locale + "-" + this.locale.toUpperCase();
    });

    firebase.analytics().logEvent('event_fine');

  }

  ngOnInit(): void {  
  }

}
