import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-test',
  templateUrl: './modal-test.component.html',
  styleUrls: ['./modal-test.component.scss']
})
export class ModalTestComponent implements OnInit {

  //INIZIO TRADUZIONI
  title1: string = "modalTest.title1"
  title2: string = "modalTest.title2"
  button1: string = 'modalTest.button1';
  button2: string = 'modalTest.button2';
  //FINE TRADUZIONI 


  email: string;
  constructor(public dialogRef: MatDialogRef<ModalTestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.email = this.data.email;

  }

  chiudiModal() {
    this.dialogRef.close(false);
  }
  conferma() {
    this.dialogRef.close(true);
  }
}
