<footer role="contentinfo" class="footer footer--slim" *ngIf="brand == 'BUDGET'">
  <div class="footer__base l-row l-row--variant-a "
    style="width: 100%; height: 106px; line-height: 60px; background: linear-gradient( to right, #002965, #0046a4);">
    <div class="l-container l-container--12">
      <ul class="footer__logo-list">
        <li class="footer__logo-list__item"><img src="./assets/images/budget/logo-white.svg"
            class="footer__logo-list__image"></li>
      </ul>
      <nav class="tertiary-navigation ">
        <ul class="tertiary-navigation__list">
          <li class="tertiary-navigation__item"><a href="{{getBudgetLink('privacyPolicy')}}"
              class="tertiary-navigation__link color-white ">{{privacyPolicy | translate}}</a></li>
          <li class="tertiary-navigation__item"><a href="{{getBudgetLink('cookiePolicy')}}"
              class="tertiary-navigation__link color-white">{{cookiePolicy | translate}}</a></li>
          <li class="tertiary-navigation__item"><a href="{{getBudgetLink('termsCondition')}}"
              class="tertiary-navigation__link color-white">{{termsCondition | translate}}</a></li>
          <li class="tertiary-navigation__item"><a href="{{getBudgetLink('contact')}}"
              class="tertiary-navigation__link color-white">{{contact | translate}}</a></li>
        </ul>
      </nav>
    </div>
  </div>
</footer>

<footer role="contentinfo" class="footer footer--slim" *ngIf="brand == 'AVIS'">
  <div class="footer__base l-row l-row--variant-a"
    style="background-color: #f1f1f1; width: 100%; height: 106px; line-height: 60px;">
    <div class="l-container l-container--12">
      <ul class="footer__logo-list">
        <li class="footer__logo-list__item"><img src="./assets/images/avi-logo-pallini.svg"
            class="footer__logo-list__image"></li>
      </ul>
      <nav class="tertiary-navigation">
        <ul class="tertiary-navigation__list">
          <li class="tertiary-navigation__item "><a href="{{getAvisLink('privacyPolicy')}}"
              class="tertiary-navigation__link color-black color-black-hover">{{privacyPolicy | translate}}</a></li>
          <li class="tertiary-navigation__item "><a href="{{getAvisLink('cookiePolicy')}}"
              class="tertiary-navigation__link color-black color-black-hover">{{cookiePolicy | translate}}</a></li>
          <li class="tertiary-navigation__item "><a href="{{getAvisLink('termsCondition')}}"
              class="tertiary-navigation__link color-black color-black-hover">{{termsCondition | translate}}</a></li>
          <li class="tertiary-navigation__item "><a href="{{getAvisLink('contact')}}"
              class="tertiary-navigation__link color-black color-black-hover">{{contact | translate}}</a></li>
        </ul>
      </nav>
    </div>
  </div>
</footer>


<footer role="contentinfo" class="footer footer--slim" *ngIf="brand == 'MAGGIORE'">
  <div class="footer__base l-row l-row--variant-a"
    style="background-color: #f1f1f1; width: 100%; height: 106px; line-height: 60px;">
    <div class="l-container l-container--12">
      <ul class="footer__logo-list">
        <li class="footer__logo-list__item"><img src="./assets/images/abg-logo.svg" class="footer__logo-list__image">
        </li>
      </ul>
      <nav class="tertiary-navigation">
        <ul class="tertiary-navigation__list">
          <li class="tertiary-navigation__item"><a href="https://www.maggiore.it/en/privacy-policy.html"
              class="tertiary-navigation__link color-black color-black-hover">{{privacyPolicy | translate}}</a></li>
          <li class="tertiary-navigation__item"><a href="https://www.maggiore.it/en/privacy-policy.html"
              class="tertiary-navigation__link color-black color-black-hover">{{cookiePolicy | translate}}</a></li>
          <li class="tertiary-navigation__item"><a
              href="http://doc.e-maggiore.net/maggiore/condizioni_prenotazione_en.pdf"
              class="tertiary-navigation__link color-black color-black-hover">{{termsCondition | translate}}</a></li>
          <li class="tertiary-navigation__item"><a href="https://www.maggiore.it/en/contacts/booking-centre/"
              class="tertiary-navigation__link color-black color-black-hover">{{contact | translate}}</a></li>
        </ul>
      </nav>
    </div>
  </div>
</footer>