import { Injectable, ComponentFactoryResolver, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, Subject, BehaviorSubject  } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
// import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
// import { GenericModalComponent } from '../shared/generic-modal/generic-modal.component';
@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }
  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }
  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}
@Injectable()
export class InterceptorService implements HttpInterceptor {
  restCallCounter: number = 0;
//   modalRef: NgbModalRef;
//   modalOptions: NgbModalOptions = {
//     centered: true
//   }
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor(private router: Router, private status: HTTPStatus) {
    this.requestInFlight$ = new BehaviorSubject(false);
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.restCallCounter++;
    setTimeout(()=>{
      this.status.setHttpStatus(true);
    }, 0);
    let authReq = req;
    return next.handle(authReq)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            return event;
          }
        }),
        catchError(err => {
          this.restCallCounter = 0;
          setTimeout(()=>{
            this.status.setHttpStatus(false);
          }, 0);
          if (err instanceof HttpErrorResponse && err.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigate(['login']);
          }
        //   else if (err instanceof HttpErrorResponse && err.status !== 401) {

        //     alert('error')
        //     // if (this.modalRef == undefined) {
        //     //   this.modalRef = this.modalService.open(GenericModalComponent, this.modalOptions);
        //     //   if (err.error.message != undefined && err.error.message.length > 0) {
        //     //     this.modalRef.componentInstance.message = err.error.message;
        //     //   }else{
        //     //     this.modalRef.componentInstance.message = err.error.error;
        //     //   }
        //     // }
        //   }
          return throwError(err);
        }),
        finalize(() => {
          this.restCallCounter--;
          if (this.restCallCounter <= 0) {
            setTimeout(()=>{
              this.status.setHttpStatus(false);
            }, 0);
          }
        })
      );
  }
}