import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrandComponent } from './feature/brand/brand.component';
import { SmartcheckoutDeleteComponent } from './feature/components/smartcheckout-delete/smartcheckout-delete.component';
import { SmartcheckoutComponent } from './feature/components/smartcheckout/smartcheckout.component';
import { SmartechkoutPrenotazioneNonTrovataComponent } from './feature/components/smartechkout-prenotazione-non-trovata/smartechkout-prenotazione-non-trovata.component';
import { HomeComponent } from './feature/home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'smartcheckout', component: SmartcheckoutComponent, pathMatch: 'full' },
  { path: 'smartcheckout-delete', component: SmartcheckoutDeleteComponent, pathMatch: 'full' },
  { path: 'smartcheckout-non-trovata', component: SmartechkoutPrenotazioneNonTrovataComponent, pathMatch: 'full' },

  { path: 'brand', component: BrandComponent, pathMatch: 'full' },


  { path: '*', redirectTo: '' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
