import { StatoSmartCheckOut } from '../enums/stato-smart-check-out.enum';
import { Brand } from './brand';
import { Desk } from './desk';
import { PickUpPoint } from './pick-up-point';
import { Reservation } from './reservation';
import { Operator } from './operator';


export class Schedule {
    priorityLables:String[]=['Alta','Media','Normale'];
    agenda: string;
    reservation: Reservation | any;
    //sarebbe il desck a cui l o schedule è assegnato
    created: Date;
    modified:Date;
    principalCreatorId: string;
    principalModifierId: string;
    assigned: string | Desk;
    appointmentDate: string | Date;
    appointmentTime: string ;
    language: any;
    note: string;
    pickUpPoint:PickUpPoint;
    status:StatoSmartCheckOut;
    //codice identificativo chiamata
    code: string;
    priority: number;
    brand: Brand;
    activeOperator:Operator;
    pickUpCongestion: any;
    timezone: string;
}

