<div class="content-fluid ">
    <div class="row m-0 bg">
        <div class="col-sm-4 offset-sm-2 font-white">
            <div class="row m-0" *ngIf="!mobile">
                <div class="col marginTop p-0 font-40">
                    {{title1 | translate}} <br>
                    {{title2 | translate}}
                </div>
            </div>

            <div class="row" *ngIf="!mobile">
                <div class="col-8 flex-center font-16 line">
                    {{subtitle | translate}} <br> <br>
                    {{subtitle2 | translate}}

                </div>
            </div>

            <div class="row m-0 p-b-15" *ngIf="mobile">
                <div class="col marginTopMobile p-0 font-40" (click)="openAccordion()">
                    <div class="row">
                        <div class="col lineHeight">
                            <span>
                                {{title1 | translate}}
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col lineHeight">
                            <span>
                                {{title2 | translate}}
                            </span>
                        </div>

                        <div class="col  flex-start-x flex-center-y ">
                            <span *ngIf="accordion">
                                <mat-icon class="font-50 ">keyboard_arrow_up</mat-icon>
                            </span>

                            <span *ngIf="!accordion">
                                <mat-icon class="font-50 ">keyboard_arrow_down</mat-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-t-10 p-b-20 p-r-15" *ngIf="accordion">
                <div class="col flex-center font-16 line">
                    {{subtitle | translate}} <br> <br>
                    {{subtitle2 | translate}}

                </div>
            </div>
        </div>
        <div class="col-sm-4 col-12 font-white font-25 d-none d-md-block m-t-25 m-b-25" *ngIf="!mobile">
            <app-card-prenotazione [isCreazione]="isCreazione" [schedule]="schedule" [resNo]="resNo" [email]="email"
                [gmt]="gmt" [targetTimezoneName]="targetTimezoneName" [startDate]="startDate" [startTime]="startTime"
                [pickUp]="pickUp"></app-card-prenotazione>
        </div>
    </div>

    <div class="row m-0">
        <div class="col d-sm-none font-18 " *ngIf="mobile">
            <form [formGroup]="smartCheckFormMobile">

                <div class="row m-0 p-t-20 p-b-20">
                    <div class="col-12 font-16  p-0">
                        <span class="primary-color bold">
                            {{numRes | translate}}
                        </span>
                    </div>

                    <div class="col-12 font-16  p-0">
                        <span>
                            {{resNo}}
                        </span>
                    </div>
                </div>

                <div class="row p-b-10">
                    <div class="col font-16">
                        <span>
                            {{yourPickUp | translate}}
                        </span>
                        <span class="bold">
                            {{schedule?.reservation.pickDate  | date: 'dd MMM yyyy HH:mm' : '' : locale}}
                        </span>
                    </div>
                </div>

                <div class="row  m-0 ">
                    <div class="col  font-12 normal-bold m-b-5 p-0">
                        {{timeZoneTitle | translate}}
                    </div>
                </div>


                <div class="row m-0 p-b-10">
                    <div class="col p-0">
                        <ngx-timezones class="standard-form__input standard-form__select selectTimezone "
                            [placeholder]="''" [guess]="true" [timezone]="targetTimezoneName" [showOffset]="true"
                            offsetName="GMT" (timezoneChange)="setTimezoneName($event)">
                        </ngx-timezones>
                    </div>
                </div>

                <!-- <div class="row m-0 p-b-10">
                    <div class="col p-0">
                        <div class="row m-0">
                            <div class="standard-form__input standard-form__select" id="timezone"
                                [ngClass]="{'borderDiv' : openChangeTimezone}"
                                (click)="openChangeTimezone = !openChangeTimezone">
                                <span class="centerVerticalText">
                                    {{targetTimezoneName?.replace('_', ' ').replace('/', ' / ')  }}

                                </span>
                                <span>
                                    (GMT {{gmt >= 0 ? '+' : ''}}{{gmt}})
                                </span>
                            </div>
                        </div>

                        <div class="row m-0 divSopra" *ngIf="openChangeTimezone">
                            <div class="col divTimepicker">
                                <span class="p-0 ui-timepicker-wrapper flex-start-x bg-grey-chiaro font-15"
                                    *ngFor="let timezone of timezoneList" (click)="setTimezoneName(timezone)">
                                    <span>
                                        {{timezone.replaceAll('_', ' ').replaceAll('/', ' / ')}}
                                    </span>
                                    <span style="margin-left: auto; min-width: 30%;">
                                        (GMT {{calculateGmt(timezone) >= 0 ? '+' : ''}}{{calculateGmt(timezone) }})
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="row m-0" *ngIf="!isCreazione">
                    <div class="col-md-12 font-16 p-0 m-b-15 ">
                        {{subtitleEdit | translate}}
                    </div>
                </div>

                <div class="row m-0">
                    <div class="col p-0 m-b-15">
                        <app-new-calendar [liveSchedule]="liveSchedule" [day]="pickDate" [schedule]="schedule"
                            (newDate)="changeDate($event)" (newTime)="changeTime($event)"
                            (liveClickable)="setClickable($event)" (gmtOutput)="gmtValue($event)" [gmt]="gmt"
                            [targetTimezoneName]="targetTimezoneName"
                            (targetTimezoneNameOutput)="timezoneNameValue($event)" (cancelDate)="cancelDate()"></app-new-calendar>
                    </div>
                </div>

                <div class="row m-0" *ngIf="!liveHidden">
                    <div class="col p-10">
                        <div class="form-check p-0">
                            <input id="checkbox1" type="checkbox" value="{{liveSchedule}}" [checked]="liveSchedule"
                                [disabled]="!liveClickable" (change)="changed($event.target.checked)" class="m-r-10">
                            <label for="checkbox1" class="font-14 noBold"> {{callPossible | translate}}</label>
                        </div>
                    </div>
                </div>

                <div class="row m-0 ">
                    <div class="col p-0 m-b-15">
                        <label class="standard-form__label font-12 bold" for="">{{insertEmail | translate}}</label>
                        {{email}}
                        <!-- <input type="text" class="standard-form__input" style="color: #8F8F8F;" name="email" id="email"
                            value="{{email}}" readonly> -->
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label class="standard-form__label font-12 bold " for="">{{insertPhone | translate}}</label>
                    </div>
                </div>
                <div class="row m-0 ">
                    <div class="col-md-3 col-4 p-0">

                        <div class="standard-form__input">
                            <ngx-intl-tel-input [cssClass]="'standard-form__input custom '" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name,SearchCountryField.DialCode]" [selectFirstCountry]="false"
                            [selectedCountryISO]="prefissoSelezionato.iso2" (countryChange)="selectPrefisso($event)"
                            [phoneValidation]="true" [separateDialCode]="true" name="prefisso" formControlName="prefisso">
                          </ngx-intl-tel-input>
                          </div> 
                       
                        <!-- <div class="row m-0">
                            <div class="standard-form__input standard-form__select" id="prefisso"
                                [ngClass]="{'borderDiv' : openPrefisso}" (click)="openPrefisso = !openPrefisso">
                                <span class="centerVerticalText">
                                    {{prefissoSelezionato }}
                                </span>
                            </div>
                        </div>
                        <div class="row m-0 divSopra" *ngIf="openPrefisso">
                            <div class="col divTimepicker">
                                <span class="p-0 ui-timepicker-wrapper flex-start-x bg-grey-chiaro font-15"
                                    *ngFor="let prefisso of prefissoList" (click)="selectPrefisso(prefisso)">
                                    {{prefisso}}
                                </span>
                            </div>
                        </div> -->
                    </div>
                    <div class="col" style="padding-right: 0;">
                        <input type="number" class="standard-form__input" name="tel" id="tel" formControlName="tel"
                            value="{{schedule?.reservation.phone}}">
                    </div>
                </div>

                <div class="row m-0"  *ngIf="nameEnviroment != 'BUDGET'">
                    <div class="col-1  p-10">
                        <div class="form-check p-0">
                            <input type="checkbox" name="checkbox-item-1" id="checkbox-item-1" value="{{whatsapp}}"
                                [checked]="whatsapp" (change)="changedWhatsapp($event.target.checked)"
                                formControlName="whatsapp" class="m-r-10">
                        </div>
                    </div>

                    <div class="col m-t-10">
                        <!-- <input id="checkbox1" type="checkbox" formControlName="whatsapp" class="m-r-10"> -->
                        <label for="checkbox2" class="font-14 noBold"> {{numbWhatsapp | translate}}</label>
                    </div>
                </div>

                <div class="row m-t-15" *ngIf="isCreazione">
                    <div class="col flex-center m-t-20">
                        <button type="submit" class="standard-form__submit button primary-background"
                            [disabled]="smartCheckFormMobile.invalid || ((!startDate || !startTime) && !liveSchedule)"
                            (click)="openDiaogConfirm()">{{button1 | translate}}</button>
                    </div>
                </div>

                <div class="row m-t-15" *ngIf="!isCreazione">
                    <div class="col-md-6 col-12 flex-center m-t-20">
                        <button type="submit" [disabled]="smartCheckFormMobile.invalid || ((!startDate || !startTime) && !liveSchedule)"
                            class="standard-form__submit button primary-background"
                            (click)="openDiaogEdit()">{{button2 | translate}}</button>
                    </div>
                    <div class="col-md-6 col-12 flex-center m-t-20">
                        <button type="submit" class="standard-form__submit  annullaButton primary-background"
                            (click)="openDiaogDelete(schedule.id)">{{button3 | translate}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <app-four-action></app-four-action>