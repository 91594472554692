<div class="row m-0">
    <div class="col flex-center bold font-30 m-t-40 m-b-40  font-size-20">
        {{yourJourney | translate}}
    </div>
</div>


<div class="row m-0  ">
    <div class="col-12  col-md-2  offset-md-2  dividerDesk" >
        <div class="row" [ngClass]="{'budget-div' : company == 'budget'}">
            <div class="col flex-center p-3 altezzaIcon ">
            
                <img src="./assets/images/{{company}}/calendar.svg">
            </div>
        </div>

        <div class="row">
            <div class="col text-center font-20 bold p-2  ">
                {{chooseCall1 | translate}} <br> {{chooseCall2 | translate}}
            </div>
        </div>
        <div class="row dividerMobile ">
            <div class="col color-subtitle font-14 p-4 text-center">
                {{subchooseCall | translate}}

            </div>
        </div>
    </div>
    <div class="col-12 col-md-2 dividerDesk">
        <div class="row" [ngClass]="{'budget-div' : company == 'budget'}" >
            <div class="col flex-center p-3 altezzaIcon">
                <img src="./assets/images/{{company}}/customer-care.svg">
            </div>
        </div>

        <div class="row">
            <div class="col text-center font-20 bold p-2">
                {{smartcheckoutOperator1 | translate}} <br>
                {{smartcheckoutOperator2 | translate}}
            </div>
        </div>
        <div class="row dividerMobile">
            <div class="col color-subtitle font-14 p-4 text-center">
                {{subSmartcheckoutOperator | translate}}
            </div>
        </div>
    </div>
    <div class="col-12 col-md-2 dividerDesk ">
        <div class="row" [ngClass]="{'budget-div' : company == 'budget'}">
            <div class="col flex-center p-3 altezzaIcon">
                <img src="./assets/images/{{company}}/shield.svg">

            </div>
        </div>

        <div class="row">
            <div class="col text-center font-20 bold p-2">
                {{pickupVehicle1 | translate}} <br>
                {{pickupVehicle2 | translate}}
            </div>
        </div>
        <div class="row dividerMobile">
            <div class="col  color-subtitle font-14 p-4 text-center">
                {{subPickupVehicle | translate}}
            </div>
        </div>
    </div>
    <div class="col-12 col-md-2 ">
        <div class="row" [ngClass]="{'budget-div' : company == 'budget'}">
            <div class="col flex-center p-3 altezzaIcon">
                <img src="./assets/images/{{company}}/car.svg">
            </div>
        </div>

        <div class="row">
            <div class="col text-center font-20 bold p-2">
                {{fastSmart1 | translate}} <br>
                {{fastSmart2 | translate}}
            </div>
        </div>
        <div class="row">
            <div class="col  color-subtitle font-14 p-4 text-center">
                {{subFastSmart | translate}}
            </div>
        </div>
    </div>
</div>