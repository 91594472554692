import * as moment from 'moment-timezone';

export class TimezoneUtils {

    static convertDateTimezone(day: string, time: string, fromTimezone: string, toTimezone: string) {
        let momentOrigin = moment.tz(day + ' ' + time, fromTimezone);
        let momentTarget = momentOrigin.clone().tz(toTimezone);
        return momentTarget;
    }

    static calculateGMT(fromTimezone: string) {
        return moment.tz(fromTimezone)['_offset'] / 60;
    }
}

//TODO: USE PREFIX
export class PhoneUtils {

    static getPhonePrefix(locale: string): string {
        switch (locale) {
            case "it":
                return "+39";
            case "en":
                return "+44";
            case "fr":
                return "+33";
            case "es":
                return "+34";
            case "de":
                return "+49";
            default:
                return "+39"
        }
    }
}